import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import {
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { GoClock } from "react-icons/go";
import { AiOutlineLeft } from "react-icons/ai";
import { BsPerson, BsPuzzle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";

import Context from "../../frontend/context";
import { Footer, LoadingSpinner } from "../";
// import SimplyBookFrame from "./SimplyBookWidget";
import style from "./style.scss";
import { initialScenarioDetailState } from '../Scenario';

const cx = classNames.bind(style);

const CURRENT_DATE_OBJ = moment();
const MAX_DATE_OBJ = moment().add(90, "days");
const DATE_FORMAT = "YYYY-MM-DD";
const MONTH_FORMAT = "YYYY-MM";
const HOUR_MINUTE_LENGTH = 5;
const MINUTES_PER_HOUR = 60;
const REDIRECT_SECONDS = 2 * 1000;
const DEFAULT_CATEGORY = "1";

// const reservationParams = { location: "", category: "", service: "" };

// OrderSteps: 
// 0. Select store, submit
// 1. Select date
// 2. Select time
// 3. Show iFrame
// 99. Rate limit exceeed

// let iFrameOptions = {
//   location: "3",
//   category: "6",
//   service: "61",
//   date: "2021-04-28",
//   time: "11:00:00",
// };

const Order = () => {
  const { isMobile, getData } = useContext(Context);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [scenarioDetail, setScenarioDetail] = useState(initialScenarioDetailState);
  const [originServiceData, setOriginServiceData] = useState([]);
  const [availableIntervalData, setAvailableIntervalData] = useState(null);
  const [requestedMonthes, setRequestedMonthes] = useState([]);
  const [orderStep, setOrderStep] = useState(0);
  const [selectedStore, setSelectedStore] = useState("");
  const [isDateFocused, setIsDateFocused] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState("");
  const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);

  const { slug } = useParams();
  const history = useHistory();
  const {
    name,
    mainCategory,
    hashtags,
    price,
    bannerUrl,
    minPlayer,
    maxPlayer,
    difficulty,
    duration,
    stores,
    isLatest,
    isREighteen,
    isHaunted,
  } = scenarioDetail;

  useEffect(() => {
    return () => {
      setOrderStep(0);
      setAvailableIntervalData(null);
      setRequestedMonthes([]);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    setIsFetchingData(true);

    getData(`/scenes/${slug}`)
      .then((res) => {
        if (res.status === 404) {
          history.push("/oops");
        } else {
          return res.json();
        }
      })
      .then((newData) => {
        setScenarioDetail((prevData) => {
          return { ...prevData, ...newData };
        });
        setIsFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        history.push("/oops");
      });
  }, [slug]);

  useEffect(() => {
    switch (orderStep) {
      case 1:
        const month = moment().format(MONTH_FORMAT);
        handleLoadMonthData(month);
        break;
    }
  }, [orderStep]);

  useEffect(() => {
    const hasOver18 = sessionStorage.getItem("hasOver18");
    let hasCheckedOver18 = false;

    if (hasOver18) {
      const checkedScenarioNames = JSON.parse(hasOver18);
      hasCheckedOver18 = checkedScenarioNames.includes(slug);
    }

    if ((isREighteen || isHaunted) && !hasCheckedOver18) {
      setIsShowAlertDialog(true);
    }
  }, [isREighteen, isHaunted]);

  const handleNextClick = () => {
    let reservationService = null, targetUrl = "";
    switch (orderStep) {
      case 0:
        if (!selectedStore) return;
        break;
      case 1:
        if (!selectedDate) return;
        break;
      case 2:
        if (!selectedInterval) return;
        const selectedFormattedDate = selectedDate.format(DATE_FORMAT);
        reservationService = originServiceData.find(serviceData => {
          return Object.keys(serviceData.available_interval).indexOf(selectedFormattedDate) > -1 && serviceData.available_interval[selectedFormattedDate].indexOf(selectedInterval) > -1;
        })

        targetUrl = `https://larpwesley.simplybook.asia/v2/#book/location/${
          reservationService.location
        }/category/${reservationService.category || DEFAULT_CATEGORY}/service/${
          reservationService.service
        }/count/1/provider/any/date/${selectedFormattedDate}/time/${selectedInterval}/`;

        setIsShowAlertDialog(true);

        setTimeout(() => {
          if (isMobile) {
            window.location.href = targetUrl;
          } else {
            window.open(targetUrl);
          }
          setIsShowAlertDialog(false);
        }, REDIRECT_SECONDS);
        break;
      case 99:
        reservationService = originServiceData[originServiceData.length - 1];
        targetUrl = `https://larpwesley.simplybook.asia/v2/#book/location/${
          reservationService.location
        }/category/${reservationService.category || DEFAULT_CATEGORY}/service/${
          reservationService.service
        }/count/1/provider/any`;

        if (isMobile) {
          window.location.href = targetUrl;
        } else {
          window.open(targetUrl);
        }
        break;
    }

    if (orderStep < 99) {
      setOrderStep((prevStep) => prevStep + 1);
    }
  };

  const handleBackClick = () => {
    if (orderStep === 1) {
      setSelectedDate("");
    }

    if (orderStep === 2) {
      setSelectedInterval("");
    }

    setOrderStep((prevStep) => prevStep - 1);
  };

  const handleStoreClick = (e) => {
    const storeName = e.target.value;

    // 若選了與剛剛不同的場館，需要將已載入的日期資料等清除
    if(selectedStore !== "" && (selectedStore !== storeName)) {
      setAvailableIntervalData(null);
      setRequestedMonthes([]);
      setIsDateFocused(false);
      setSelectedDate(null);
      setSelectedInterval("");
    }

    setSelectedStore(storeName);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateFocused = ({ focused }) => {
    setIsDateFocused(focused);
  };

  const defineAvailableDateRange = (day) => {
    // 當天以前的日期設為 block
    return (
      day.isSameOrBefore(CURRENT_DATE_OBJ) || day.isSameOrAfter(MAX_DATE_OBJ)
    );
  };

  const defineAvaialbleDate = useCallback(
    (day) => {
      // 尚未取得可用日期，設為 block
      if (availableIntervalData === null) return true;

      const date = day.format(DATE_FORMAT);

      const compareDate = availableIntervalData[date];

      // 已取得可用日期，但該天沒有可用場次，設為 block
      if (compareDate && compareDate.length === 0) return true;

      // 其餘則為可選日期
      return false;
    },
    [availableIntervalData]
  );

  const handleLoadMonthData = (month) => {
    if (requestedMonthes.includes(month)) return;

    setIsFetchingData(true);

    getData(`/scenes/available_interval?slug=${slug}&branch=${selectedStore}&month=${month}`)
      .then((res) => {
        if (res.status === 404) {
          history.push("/oops");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        // { location, category, service, available_interval } = data.results[index];

        if (data.results && data.results[0] && data.results[0].available_interval === null) {
          setOrderStep(99);
        } else {
          const newData = data.results.reduce((accu, dataObj, idx) => { 
            if (idx === 0) {
              // in most cases has only one service
              return dataObj.available_interval;
            } else {
              // very rare case has more multiple services
              return Object.keys(dataObj.available_interval).reduce((newAccu, date) => {
                if (date in newAccu) {
                  const newDateArray = [...newAccu[date], ...dataObj.available_interval[date]].sort((a, b) => {
                    // sort by early to late
                    return parseInt(a.split(":").join("")) - parseInt(b.split(":").join(""))
                  });
                  return {
                    ...newAccu,
                    [date]: newDateArray
                  }
                } else {
                  return {
                    ...newAccu,
                    [date]: dataObj.available_interval[date]
                  }
                }
              }, accu);
            }
          }, {});
        
          setAvailableIntervalData((prevData) => {
            if (prevData === null) {
              return newData;
            } else {
              return { ...prevData, ...newData }
            }
          });
        }
        setOriginServiceData(prevData => [ ...prevData, ...data.results ])
        setIsFetchingData(false);
        setRequestedMonthes([...requestedMonthes, month]);
      })
      .catch((err) => {
        console.log(err);
        history.push("/oops");
      });
  };

  const handleNextMonthClick = (day) => {
    // 只取今天起三個月內的可用月份
    if (
      day.isBefore(CURRENT_DATE_OBJ, "month") ||
      day.isAfter(MAX_DATE_OBJ, "month")
    ) return;

    const month = day.format(MONTH_FORMAT);

    handleLoadMonthData(month);
  };

  const handleIntervalClick = e => {
    const value = e.target.value;

    setSelectedInterval(value);
  };

  const renderOrderInfo = () => {
    let headerText = ""; 
    let isAbleToNext = false;

    switch (orderStep) {
      case 0:
        headerText = "選擇場館";
        isAbleToNext = !!selectedStore;
        break;
      case 1:
        headerText = "選擇日期";
        isAbleToNext = !!selectedDate;
        break;
      case 2:
        headerText = "選擇時段";
        isAbleToNext = !!selectedInterval;
        break;
      case 99:
        headerText = "選擇時段";
        isAbleToNext = true;
        break;
    };

    return (
      <div className={cx("order-page__order-info-container")}>
        <div className={cx("order-info", "order-detail")}>
          <p>預約場館：{selectedStore}</p>
          <p>
            預約日期：{selectedDate ? selectedDate.format(DATE_FORMAT) : ""}
          </p>
          <p>預約時間：{selectedInterval.substring(0, HOUR_MINUTE_LENGTH)}</p>
        </div>

          <div className={cx("order-info", "order-action")}>
            <div className={cx("order-action__header")}>
              {orderStep > 0 && orderStep !== 99 && (
                <button
                  className={cx("order-action__back-button")}
                  onClick={handleBackClick}
                >
                  <Icon
                    as={AiOutlineLeft}
                    boxSize={isMobile ? "14px" : "21px"}
                  />
                  返回
                </button>
              )}
              <p>{headerText}</p>
            </div>
            <div
              className={cx("order-action__body")}
              style={orderStep === 1 || orderStep === 99  ? { justifyContent: "center" } : {}}
            >
              {renderActionBody()}
            </div>
            <div className={cx("order-action__footer")}>
              <button
                className={cx("order-action__next-button", "action-button", {
                  active: isAbleToNext,
                })}
                onClick={handleNextClick}
                disabled={!isAbleToNext}
              >
                下一步
              </button>
            </div>
          </div>
      </div>
    );
  };

  const handleCloseAlertDialog = () => {
    const hasOver18 = sessionStorage.getItem("hasOver18");
    let prevHasOver18 = [];

    if (hasOver18) {
      prevHasOver18 = JSON.parse(hasOver18);
    }

    const newHasOver18 = JSON.stringify([...prevHasOver18, slug]);

    sessionStorage.setItem("hasOver18", newHasOver18);

    setIsShowAlertDialog(false);
  };

  const handleReturn = () => {
    if (window.history.length < 3) {
      history.push("/scenarios");
    } else {
      window.history.go(-1);
    }
  };

  const renderActionBody = () => {
    switch(orderStep) {
      case 0:
        return (
          stores.map((store, idx) => {
            return (
              <button
                key={`${store}-${idx}`}
                className={cx("order-action__store-button", "action-button", {
                  active: selectedStore === store,
                })}
                value={store}
                onClick={handleStoreClick}
              >
                {store}
              </button>
            );
          })
        )
      case 1:
        return (
          <DayPickerSingleDateController
            date={selectedDate}
            onDateChange={handleDateChange}
            focused={isDateFocused}
            onFocusChange={handleDateFocused}
            isDayBlocked={defineAvaialbleDate}
            isOutsideRange={defineAvailableDateRange}
            numberOfMonths={1}
            monthFormat="YYYY[年]MMMM"
            hideKeyboardShortcutsPanel={true}
            onNextMonthClick={handleNextMonthClick}
          />
        );
      case 2:
        const intervals = availableIntervalData[selectedDate.format(DATE_FORMAT)];
        return intervals.map((interval) => {
          const beginTimeArr = interval.split(":");
          const endTimeMinute = parseInt(beginTimeArr[1]) + duration;
          const entTimeArr = [
            parseInt(beginTimeArr[0]) +
              Math.floor(endTimeMinute / MINUTES_PER_HOUR),
            endTimeMinute % MINUTES_PER_HOUR,
          ];

          return (
            <button
              key={interval}
              className={cx("order-action__store-button", "action-button", {
                active: selectedInterval === interval,
              })}
              value={interval}
              onClick={handleIntervalClick}
            >
              {`${beginTimeArr[0]}:${beginTimeArr[1]} - ${entTimeArr[0]}:${
                entTimeArr[1] || "00"
              }`}
            </button>
          );
        });
        break;
      case 99:
        return (
          <div>
            <p>請點選以下按鈕直接前往預約系統查看場次。</p>
          </div>
        )
    }
  };

  const renderScenarioInfo = () => {

    return (
      <div className={cx("order-page__scenario-info-container")}>
        {isMobile ? (
          <div className={cx("scenario-info-mobile")}>
            <div className={cx("scenario-info__main-info")}>
              <div
                className={cx("scenario-info__banner")}
                style={{
                  backgroundImage: `url(${bannerUrl}`,
                }}
              />
              <div className={cx("scenario-info")}>
                <Link to={`/scenario/${slug}`}>
                  <h1>{name}</h1>
                </Link>
                <div className={cx("scenario-info__hashtags")}>
                  {hashtags.length ? (
                    hashtags.map((tag, idx) => {
                      return <span key={`${tag}-${idx}`}>#{tag}</span>;
                    })
                  ) : (
                    <span>#</span>
                  )}
                </div>
              </div>
            </div>
            <div className={cx("scenario-info__sub-info")}>
              <div>
                <Icon as={GoClock} boxSize="14px" />
                <span>{duration} min</span>
              </div>
              <div>
                <Icon as={BsPerson} boxSize="14px" />
                <span>
                  {minPlayer === maxPlayer
                    ? `${minPlayer} 人`
                    : `${minPlayer}~${maxPlayer} 人`}
                </span>
              </div>
              <div>
                <Icon as={BsPuzzle} boxSize="14px" />
                <span>
                  {typeof mainCategory === "string" &&
                    mainCategory.substring(0, 2)}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className={cx("scenario-main-card-container")}>
            <div className={cx("card__main-info")}>
              {isLatest && <div className={cx("card-tag")}>最新</div>}
              <div className={cx("card-difficulty", difficulty)} />
              <div
                className={cx("card-banner-container")}
                style={{
                  backgroundImage: `url(${bannerUrl}`,
                }}
              ></div>
              <Link to={`/scenario/${slug}`}>
                <h1>{name}</h1>
              </Link>
              <div className={cx("card-hashtags")}>
                {hashtags.length ? (
                  hashtags.map((tag, idx) => {
                    return <span key={`${tag}-${idx}`}>#{tag}</span>;
                  })
                ) : (
                  <span>#</span>
                )}
              </div>
            </div>
            <div className={cx("card__sub-info")}>
              <div>
                <Icon as={GoClock} boxSize={isMobile ? "14px" : "30px"} />
                <span>{duration} min</span>
              </div>
              <div>
                <Icon as={BsPerson} boxSize={isMobile ? "14px" : "30px"} />
                <span>
                  {minPlayer === maxPlayer
                    ? `${minPlayer} 人`
                    : `${minPlayer}~${maxPlayer} 人`}
                </span>
              </div>
              <div>
                <Icon as={BsPuzzle} boxSize={isMobile ? "14px" : "30px"} />
                <span>
                  {typeof mainCategory === "string" &&
                    mainCategory.substring(0, 2)}
                </span>
              </div>
              {/* TOADD: price */}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAlertDialog = () => {
    let bodyText

    if (isREighteen && isHaunted) {
      bodyText = 
        <span>
          部分故事及遊戲過程含有
          <span style={{ color: "#B3390E" }}>限制級情節及驚嚇橋段</span>
        </span>
    } else if (isREighteen) {
      bodyText = 
        <span>
          部分故事含有
          <span style={{ color: "#B3390E" }}>限制級情節</span>
        </span>
    } else {
      bodyText = 
        <span>
          遊戲過程含有
          <span style={{ color: "#B3390E" }}>驚嚇橋段</span>
        </span>
    }

    if (orderStep >= 2) {
      return (
        <Modal
          isOpen={isShowAlertDialog}
          autoFocus={false}
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent p={5} mx={4} borderRadius="19px">
            <ModalBody
              textAlign="center"
              fontSize={isMobile ? "16px" : "24px"}
              color="#1E202D"
              fontWeight="900"
            >
              <Icon
                as={FiAlertTriangle}
                color="#1E202D"
                boxSize={isMobile ? "40px" : "60px"}
              />
              <p>即將帶您前往預約系統，<br/>請稍候...</p>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
    };

    return (
      <Modal
        isOpen={isShowAlertDialog}
        onClose={handleReturn}
        autoFocus={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent pt={5} mx={4} borderRadius="19px">
          <ModalCloseButton color="#1D1C20" />
          <ModalBody
            textAlign="center"
            fontSize={isMobile ? "16px" : "24px"}
            color="#0A0A0A"
            borderBottomWidth="1px"
            borderColor="#E3E6E6"
          >
            <Icon
              as={FiAlertTriangle}
              color="#B3390E"
              boxSize={isMobile ? "40px" : "60px"}
            />
            <h6
              style={{
                margin: isMobile ? "12px auto 25px" : "22px auto 30px",
                color: "#B3390E",
                fontWeight: 900,
              }}
            >
              警告
            </h6>
            <p style={{ marginBottom: isMobile ? "29px" : "34px" }}>
              此劇本
              { bodyText }
              ，請<strong>年滿十八歲之參與玩家需</strong>斟酌考慮適應程度後，再行預訂。
            </p>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <button
              className={cx("modal-button", "agree")}
              onClick={handleCloseAlertDialog}
            >
              同意
            </button>
            <button className={cx("modal-button")} onClick={handleReturn}>
              回上一頁
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };


  return (
    <div className={cx("order-page")}>
      <Helmet>
        <title>預訂劇本： {name} | 謀殺衛斯理 Mwlarp.com</title>
      </Helmet>
      {isShowAlertDialog && renderAlertDialog()}
      {isFetchingData && <LoadingSpinner />}
      <div className={cx("order-page-inner")}>
        <h2 className={cx("order-page-title")}>預訂劇本</h2>
        <div className={cx("order-page-inner__primary")}>
          {renderOrderInfo()}
          {renderScenarioInfo()}
        </div>
        {/* {orderStep >= 3 && <SimplyBookFrame {...iFrameOptions} />} */}
      </div>
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default Order;