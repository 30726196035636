import React from "react";

const Template = () => {
  return (
    <>
      <li>
        網頁上顯示的金額為訂金NT$1000，尾款請於活動當天繳清。訂金請於訂場後24小時內支付，逾期則場次自動釋出不另行通知。
      </li>
      <li>
        支付方式有兩種：
        <br />
        <strong>A. 信用卡線上刷卡</strong>
        <br />
        以「信用卡線上刷卡」支付時，需於付款頁面直接付款。如未於第一時間付款，為保護您的個資，系統付款頁面將不予保留，需「取消預約」後「重新預訂」，方能再次刷卡預約。（為保護您的訂位優先權益，建議第一時間支付，以免在退訂、重訂之時間差中，導致您的訂位保留權益受損喔！）
        <br />
        <strong>B. 超商付款</strong>
        <br />
        以「超商付款」支付時，系統將提供繳費代碼，請於24小時內至超商繳費即完成付款。
        <br />
        付款完成後，系統將在30分鐘內寄送預約成功之電子郵件。如未收到請依據遊戲場館私訊臉書粉絲專頁聯繫客服人員。台北天祥館請私訊「謀殺衛斯理工作室」；台北明曜館請私訊「謀殺衛斯理明曜館」；桃園中原館請私訊「衛斯理探案館 中原館」。
        <br />
        粉專客服時間：周一至周日 10:30 - 18:30
      </li>
      <li>
        請務必詳加確認訂購之場次、劇本及人數，玩家人數需完全符合劇本設定，舉例：陽關客棧為8人劇本，必須剛好8人遊玩，人數增減都將導致「遊戲無法進行」！
        <strong>訂單成立後如經自行取消，恕無法退還款項</strong>。
      </li>
      <li>
        活動當日『前5日』<span style={{ color: "#B3390E" }}>內</span>
        即不得更換場次及劇本。例：6/20為活動日，6/15起即不受理更換場次及劇本，需於6/14(含)前提出申請。
      </li>
      <li>
        活動當日『前2日』<span style={{ color: "#B3390E" }}>內</span>
        即不得更改人數。例：6/20為活動日，6/18起即不受理更換人數，需於6/17(含)前提出申請。
      </li>
      <li>
        如有更換場次、劇本及人數的需求，請依據遊戲場館私訊臉書粉絲專頁聯繫客服人員，<span style={{ color: "#B3390E" }}>訂單成立後如經自行取消，恕無法退還款項。</span>台北天祥館請私訊「謀殺衛斯理工作室」；台北明曜館請私訊「謀殺衛斯理明曜館」；桃園中原館請私訊「衛斯理探案館 中原館」。
      </li>
      <li>
        於活動中如因非可究責主辦單位之因素
        <strong>導致活動無法進行或提前中止</strong>，將不予
        <strong>退還</strong>款項。例：當日出席人數不符合遊戲限制、
        <strong>同行玩家</strong>遲到或早退等。
      </li>
      <li>
        如因天災等不可抗力因素導致遊戲無法進行（如颱風、停電），我們將暫停當日營業，並主動向您聯繫延期或退款事項。
      </li>
      <li>
        因部分故事情節涵蓋不宜未滿18歲以下青少年之內容，為維護其身心安全，部分劇本禁止18歲以下之青少年遊玩，另有其他劇本提供15-18歲之青少年可選擇。而因遊戲涵蓋不適合15歲以下青少年之內容，<strong>謝絕15歲以下之青少年及兒童進場</strong>。
      </li>
      <li>
        於遊戲過程中，請玩家勿以人身攻擊、諷刺調侃、髒話謾罵、
        挑釁引戰傷害其他玩家或工作人員，若發生造成工作人員身心不適之情形，謀殺衛斯理工作室有權直接終止遊戲，不予退還遊戲費用。
      </li>
    </>
  )
};

export default Template;
