import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { GoLocation, GoClock } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";

import { offlineBranchKeys, branchDetail } from '../staticData';
import { Footer, ScenarioCard } from '../';
import Context from "../../frontend/context";
import style from "./style.scss";

const cx = classNames.bind(style);

const START_OF_OPENING_HOUR = 12;
const END_OF_OPENING_HOUR = 20;

const Home = () => {
  const { isMobile, getData } = useContext(Context);
  const [topicList, setTopicList] = useState([]);
  const [faqList, setFaqList] = useState([])
  const [isShowToday, setIsShowToday] = useState(false);
  const [chosenStore, setChosenStore] = useState('1'); 

  const time = new Date();
  const currentHour = time.getHours();

  useEffect(() => {
    window.scrollTo(0, 0);

    getData(`/home`)
      .then((res) => {
        if (res.status === 404) {
          history.push("/oops");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        const { faq, topics, showToday } = data;
        setTopicList(topics);
        setFaqList(faq);

        // 先判斷是否營業時間(12~20)，是的話再以 API 參數為準
        if (checkIsInOpeningHour()) {
          setIsShowToday(showToday)
        }
      })
      .catch((err) => {
        console.log(err)
        history.push("/oops");
      });
  }, [])

  const checkIsInOpeningHour = () => {
    return currentHour >= START_OF_OPENING_HOUR && currentHour < END_OF_OPENING_HOUR
  };

  const getCountdown = () => {
    const hourStr = (END_OF_OPENING_HOUR - 1 - currentHour).toString();
    const minuteStr = (60 - time.getMinutes()).toString();

    return {
      h: '0' + hourStr,
      m: minuteStr.length === 1 ? '0' + minuteStr : minuteStr,
    }
  };

  const handleStoreChoose = e => {
    const store = e.target.value;

    setChosenStore(prev => store);
  }

  const renderTopics = useCallback(() => {

    return topicList.map((topic, idx) => {
      const { name, description, scenes } = topic;

      return (
        <div
          key={`${name}-${idx}`}
          className={cx("scenarios-carousel-container")}
        >
          <h4>{name}</h4>
          <p>{description}</p>
          {isMobile ? (
            <div className={cx("scenario-cards-container")}>
              {scenes.map((sceneData, i) => (
                <Link to={`/scenario/${sceneData.slug}`} key={sceneData.id}>
                  <ScenarioCard idx={i} isMobile {...sceneData} />
                </Link>
              ))}
            </div>
          ) : (
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation
              pagination
              onSlideChange={(swiper) => console.log(swiper.activeIndex)}
            >
              {scenes.map((sceneData, i) => (
                <SwiperSlide>
                  <Link to={`/scenario/${sceneData.slug}`} key={sceneData.id}>
                    <ScenarioCard idx={i} isAlignCenter {...sceneData} />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      );
    })
  }, [topicList])

  const renderFaq = useCallback(() => {
    const faq = ({ ele, isLast }) => (
      <AccordionItem mb={isLast ? 0 : 4} border="none">
        <AccordionButton
          py={isMobile ? 5 : 7}
          px={isMobile ? 5 : 8}
          bg="#FFFFFF"
          color="#000000"
          borderRadius={isMobile ? "8px" : "15px"}
          _hover={{
            bg: "#FFFFFF",
          }}
          _expanded={
            isMobile
              ? {
                  borderRadius: "8px 8px 0 0",
                }
              : {
                  borderRadius: "15px 15px 0 0",
                }
          }
        >
          <Box flex="1" textAlign="left">
            <p className={cx("scenario-page__faq-title")}>{ele.question}</p>
          </Box>
          <AccordionIcon w={isMobile ? 8 : 10} h={isMobile ? 8 : 10} />
        </AccordionButton>
        <AccordionPanel
          py={isMobile ? 4 : "30px"}
          px={isMobile ? 5 : "56px"}
          bg="#F0F3FA"
          color="#3D3B3B"
          borderBottomRightRadius={isMobile ? "8px" : "15px"}
          borderBottomLeftRadius={isMobile ? "8px" : "15px"}
        >
          {ele.answer}
        </AccordionPanel>
      </AccordionItem>
    );

    return (
      <div className={cx("faq-wrapper")}>
        <Accordion allowMultiple>
          {faqList.map((ele, i) => {
            return faq({ ele, isLast: i === faqList.length - 1 });
          })}
        </Accordion>
      </div>
    );
  }, [faqList]); 

  const renderStoresSection = () => {
    return (
      <div className={cx("stores-section")}>
        <div className={cx("stores-section__content")}>
          <h3>場館地點</h3>
          <p className={cx("content__brief")}>三間分店，任君挑選</p>
          {isMobile && renderMap()}
          <div className={cx("stores-section__tabs")}>
            <button
              className={cx("store-tab", {
                "is-chosen": chosenStore === "1",
              })}
              onClick={handleStoreChoose}
              value="1"
            >
              {branchDetail.ts.name}
            </button>
            <button
              className={cx("store-tab", {
                "is-chosen": chosenStore === "2",
              })}
              onClick={handleStoreChoose}
              value="2"
            >
              {branchDetail.my.name}
            </button>
            <button
              className={cx("store-tab", {
                "is-chosen": chosenStore === "3",
              })}
              onClick={handleStoreChoose}
              value="3"
            >
              {branchDetail.cy.name}
            </button>
          </div>
          {!isMobile && renderMap()}
          <div className={cx("store-detail-container")}>
            {offlineBranchKeys.map(branchKey => {
              const { name, address, openingHours, tel } = branchDetail[branchKey];
              return (
                <div className={cx("store-detail")} key={name}>
                  <h6>{name}</h6>
                  <p>
                    <Icon as={GoLocation} mt={1} ml={1} mr={2} />
                    <span className={cx("address")}>{address}</span>
                  </p>
                  <p>
                    <Icon as={GoClock} mt={1} ml={1} mr={2} />
                    <span>{openingHours[0]}</span>
                  </p>
                  {tel.map(t => (
                    <p key={`${branchKey}-${t}`}>
                      <Icon as={BiPhoneCall} mt={1} ml={1} mr={2} />
                      <span>{t}</span>
                    </p>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderMap = () => {
    let address = "";

    switch (chosenStore) {
      case "1":
        // 天祥館
        address =
          "%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%A4%A9%E7%A5%A5%E8%B7%AF35%E8%99%9F";
        break;
      case "2":
        // 明曜館
        address = "台北市大安區忠孝東路四段176-1號10樓";
        break;
      case "3":
        // 中原館
        address =
          "%E6%A1%83%E5%9C%92%E5%B8%82%E4%B8%AD%E5%A3%A2%E5%8D%80%E5%A4%A7%E4%BB%81%E4%B8%80%E8%A1%975%E8%99%9F";
        break;
    }

    return (
      <div className={cx("store-map-container")}>
        <iframe 
          width="600" 
          height="500" 
          src={`https://maps.google.com/maps?q=${address}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
          frameBorder="0" 
          scrolling="no" 
          marginHeight="0" 
          marginWidth="0"
          style={{ width: "100%", height: "100%", borderRadius: "14px" }}
        />
      </div>
    )
  }

  return (
    <div className={cx("home-page")}>
      <Helmet>
        <title>謀殺衛斯理 Mwlarp.com</title>
      </Helmet>
      <div className={cx("hero-section")}>
        <div className={cx("deco-mask", "--hero")} />
        <div className={cx("deco-mask", "--hero", "--sub")} />
        <div className={cx("hero-section__content")}>
          <div className={cx("content__logo-container")} />
          <h2>展開你的第二人生</h2>
          <p>如果有天你可以成為另一個人，過一個完全不同的人生，你會怎麼做？</p>
          <Link to="scenarios">
            <button className={cx("order-now-button")}>立即預訂劇本</button>
          </Link>
        </div>
      </div>
      <div className={cx("intro-section")}>
        <div className={cx("deco-mask", "--intro")} />
        <div className={cx("intro-section__content")}>
          <div className={cx("content__logo-container")} />
          <h3>關於我們</h3>
          <p className={cx("content__brief", "--center")}>
            歡迎來到謀殺衛斯理工作室
          </p>
          <p className={cx("content__description")}>
            我們是全臺最大的LARP實況劇場遊戲館，致力給每一位客人創造獨一無二的美好遊戲體驗，這裡可以讓你進入不同的角色劇本，親身感受角色間的愛恨情仇，經歷一段不曾體驗過的生命旅途。
          </p>
          <div className={cx("scroll-down")} />
        </div>
        <div className={cx("intro-section__bg--left")} />
        <div className={cx("intro-section__bg--right")} />
      </div>
      <div className={cx("larp-flow-section")}>
        <div className={cx("deco-mask", "--flow")} />
        <div className={cx("larp-flow-section__content")}>
          <h3>什麼是LARP實況劇場遊戲？</h3>
          <p className={cx("content__brief", "--center")}>
            透過角色扮演、沈浸氛圍，
          </p>
          <p className={cx("content__brief", "--center")}>
            體驗故事劇情的互動遊戲。
          </p>
          <div className={cx("larp-feature-container", "--roleplay")}>
            {!isMobile && (
              <div className={cx("larp-feature__heading")}>
                <p>01</p>
                <h6>角色扮演</h6>
              </div>
            )}
            <div className={cx("larp-feature__content")}>
              <div className={cx("larp-feature__symbol")} />
              {isMobile && <h6>角色扮演</h6>}
              <p>
                你能從角色的眼睛見其所見，用角色的靈魂思其所想，你的選擇將決定他的命運。
              </p>
            </div>
          </div>
          <div className={cx("larp-feature-container", "--immersive")}>
            {!isMobile && (
              <div className={cx("larp-feature__heading")}>
                <p>02</p>
                <h6>沈浸體驗</h6>
              </div>
            )}
            <div className={cx("larp-feature__content")}>
              <div className={cx("larp-feature__symbol")} />
              {isMobile && <h6>沈浸體驗</h6>}
              <p>
                華美服裝、精緻場景以及豐富劇情，無論是歡笑、愛戀、悲傷或是恐懼，我們全都呈現給您！
              </p>
            </div>
          </div>
          <div className={cx("larp-feature-container", "--truth")}>
            {!isMobile && (
              <div className={cx("larp-feature__heading")}>
                <p>03</p>
                <h6>真相推理</h6>
              </div>
            )}
            <div className={cx("larp-feature__content")}>
              <div className={cx("larp-feature__symbol")} />
              {isMobile && <h6>真相推理</h6>}
              <p>
                人生來都渴望追求真相，全力隱藏自己的底細，用觀察和試探使他人的秘密無所遁形，進而完美還原事情真相！
              </p>
            </div>
          </div>
          <div className={cx("scroll-down")} />
        </div>
      </div>
      <div className={cx("scenarios-section")}>
        <div className={cx("deco-mask", "--category")} />
        <div className={cx("scenarios-section__content")}>
          <h3>劇本類別</h3>
          <p className={cx("content__brief")}>五大類別，找到專屬你最愛的劇本</p>
          <div className={cx("scenario-category-container")}>
            <div className={cx("scenario-category-row")}>
              <Link
                to="/scenarios/歡樂劇本"
                className={cx("scenario-category")}
              >
                <div className={cx("scenario-category__head")} />
                <div className={cx("scenario-category__body")}>
                  <h5 className={cx("scenario-category__title")}>歡樂劇本</h5>
                  <p className={cx("scenario-category__detail")}>
                    想體驗友誼的小船說翻就翻嗎？選擇歡樂本就對了！
                  </p>
                </div>
              </Link>
              <Link
                to="/scenarios/靈異劇本"
                className={cx("scenario-category")}
              >
                <div className={cx("scenario-category__head")} />
                <div className={cx("scenario-category__body")}>
                  <h5 className={cx("scenario-category__title")}>靈異劇本</h5>
                  <p className={cx("scenario-category__detail")}>
                    尖叫、鬼吼，來自未知的恐懼就潛伏在陰影之中······
                  </p>
                </div>
              </Link>
              <Link
                to="/scenarios/求生劇本"
                className={cx("scenario-category")}
              >
                <div className={cx("scenario-category__head")} />
                <div className={cx("scenario-category__body")}>
                  <h5 className={cx("scenario-category__title")}>求生劇本</h5>
                  <p className={cx("scenario-category__detail")}>
                    在這適者生存的世界，小心想拖你下水的人就在身邊
                  </p>
                </div>
              </Link>
            </div>
            <div className={cx("scenario-category-row")}>
              <Link
                to="/scenarios/情感劇本"
                className={cx("scenario-category")}
              >
                <div className={cx("scenario-category__head")} />
                <div className={cx("scenario-category__body")}>
                  <h5 className={cx("scenario-category__title")}>情感劇本</h5>
                  <p className={cx("scenario-category__detail")}>
                    橫跨千年的約定，或是平易近人的美好，將其撰寫成冊，必能令你嘗盡人生百態。
                  </p>
                </div>
              </Link>
              <Link
                to="/scenarios/推理劇本"
                className={cx("scenario-category")}
              >
                <div className={cx("scenario-category__head")} />
                <div className={cx("scenario-category__body")}>
                  <h5 className={cx("scenario-category__title")}>推理劇本</h5>
                  <p className={cx("scenario-category__detail")}>
                    靠著線索與文本中的蛛絲馬跡，推理出整個故事的真相吧！
                  </p>
                </div>
              </Link>
            </div>
          </div>
          {renderTopics()}
          {isShowToday && (
            <div className={cx("scenarios-today")}>
              <div className={cx("scenarios-today__desc")}>
                <h4>
                  <span>本</span>
                  <span>日</span>
                  <span>推</span>
                  <span>薦</span>
                  <span>劇</span>
                  <span>本</span>
                </h4>
                <p>用限定的價格，解鎖每日限時劇本</p>
              </div>
              <div className={cx("scenarios-today__cta")}>
                <span id="count-down-timer">
                  {getCountdown().h}
                  <span id="count-down-timer--saperator">:</span>
                  {getCountdown().m}
                </span>
                <button>
                  <Link to="/post/today">
                    <span>搶先預訂</span>{" "}
                  </Link>
                  <ChevronRightIcon w={isMobile ? 5 : 10} h={isMobile ? 5 : 10} />
                </button>
              </div>
            </div>
          )}
          <div className={cx("faq-section")}>
            <div className={cx("faq-section__content")}>
              <h3>常見問題</h3>
              <p className={cx("content__brief")}>十萬個為什麼</p>
              {renderFaq()}
            </div>
          </div>
          <div className={cx("order-now-section")}>
            <div className={cx("order-now-section__content")}>
              <h3>立即預約</h3>
              <p className={cx("content__brief")}>這一次，換你當主角</p>
              <p className={cx("content__brief")}>
                準備好體驗你的第二人生了嗎？
              </p>
              <Link to="/scenarios">
                <button className={cx("order-now-button")}>立即預訂劇本</button>
              </Link>
            </div>
            <div className={cx("order-now-section__bg")}>
              <div className={cx("order-now__symbol", "--left-top")} />
              <div className={cx("order-now__symbol", "--right-top")} />
              <div className={cx("order-now__symbol", "--right-bottom")} />
              <div className={cx("order-now__symbol", "--left-bottom")} />
            </div>
          </div>
        </div>
      </div>
      {renderStoresSection()}
      <Footer />
    </div>
  );
}

export default Home;