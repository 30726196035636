import React from "react";

const Template = () => {
  return (
    <>
      <li>
        警告：此劇本遊戲過程含有驚嚇橋段，預約前請詳閱以下規章。
      </li>
      <li>
        此劇本因故事情節將有些微驚嚇橋段。若有下列疾病、症狀之人員，不建議參加本次活動，如：心臟病、高血壓、凝血功能不足、密閉空間恐懼症、嚴重氣喘、孕婦、癲癇、其他法定傳染病或飲酒、宿醉、濫用藥物……等相關症狀。若工作人員評估身體狀況後經過勸阻仍決定參加本次活動，參與人須承擔活動進行之一切風險。
      </li>
      <li>
        工作人員於遊戲過程中不會在非劇情需要之處刻意碰觸玩家，也請玩家
        <strong>
          勿以人身攻擊、諷刺調侃、髒話謾罵、 挑釁引戰；肢體
        </strong>
        碰觸、傷害或攻擊工作人員，
        <strong>若發生造成工作人員身心不適或受傷之情形</strong>
        ，謀殺衛斯理工作室有權直接終止遊戲，不予退還遊戲費用。
      </li>
    </>
  )
};

export default Template;
