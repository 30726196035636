import React from "react";
import { Icon } from "@chakra-ui/react";
import { BsPerson } from "react-icons/bs";
import classNames from "classnames";
import style from "./style.scss";

const cx = classNames.bind(style);

const ScenarioCard = (props) => {
  const {
    name = "",
    bannerUrl = "",
    hashtags = [],
    minPlayer = 0,
    maxPlayer = 0,
    price = 0,
    priceUnit = "人",
    difficulty = "",
    mainCategory = "",
    playerComposition = "",
    intro = "",
    isLatest = false,
    isMobile = false,
    isAlignCenter = false,
  } = props;

  const createMarkup = () => { return { __html: intro }};

  const renderHashtags = () => {
    if (hashtags === null || !hashtags.length) {
      return <span>#</span>
    }

    return hashtags.map((tag, idx) => (
      <span key={`${tag}-${idx}`}>#{tag}</span>
    )); 
  };

  const renderPlayers = () => {
    return minPlayer === maxPlayer ? `${minPlayer} 人` : `${minPlayer} ~ ${maxPlayer} 人`;
  };

  return (
    <div className={cx("scenario-card-container", { center: isAlignCenter })}>
      <div className={cx("card__main-info")}>
        {isLatest && <div className={cx("card-tag")}>最新</div>}
        <div className={cx("card-difficulty", difficulty)} />
        <div className={cx("card-banner-container")}>
          <img src={bannerUrl} loading="lazy" alt={name} />
        </div>
        <div className={cx("card-hashtags")}>{renderHashtags()}</div>
        <h4 className={cx("card-title")}>{name}</h4>
        <div className={cx("card-people")}>
          <Icon as={BsPerson} boxSize={isMobile ? "14px" : "16px"} />
          <span>{renderPlayers()}</span>
        </div>
        <p className={cx("card-price")}>
          <span className={cx("card-price--prefix")}>NT$</span>
          <span>{price}/{priceUnit}</span>
        </p>
      </div>
      <div className={cx("card__sub-info")}>
        <div className={cx("card-difficulty--hover", difficulty)} />
        <p className={cx("card-category")}>
          <span>{mainCategory}</span>
          {playerComposition && <span>({playerComposition})</span>}
        </p>
        <p
          className={cx("card-intro")}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
    </div>
  );
};

export default ScenarioCard;
