import React, { useState, useRef, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { BiHome } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { GoClock } from "react-icons/go";
import classNames from "classnames";

import Context from '../../frontend/context';
import style from './style.scss';

const cx = classNames.bind(style);

const Navbar = () => {
  const { isMobile } = useContext(Context);
  const [isOpenMobileDrawer, setIsOpenMobileDrawer] = useState(false);
  const navBarRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return (() => {
      window.removeEventListener("scroll", handleScroll);
    })
  }, []);

  const handleScroll = () => {
    const verticalScrollPercent = (window.scrollY - window.innerHeight) / window.innerHeight;

    if (verticalScrollPercent >= 1) {
      navBarRef.current.style.backgroundColor = "rgb(31, 33, 50)";
    } else if (verticalScrollPercent < 1 && verticalScrollPercent > -0.9) {
      navBarRef.current.style.backgroundColor = `rgba(31, 33, 50, ${(verticalScrollPercent + 1)})`;
    } else {
      navBarRef.current.style.backgroundColor = "transparent";
    }
  };

  const handleMobileDrawerToggle = () => {
    setIsOpenMobileDrawer(prevState => !prevState);
  };

  const renderMobileDrawer = () => {
    return (
      <Drawer
        isOpen={isOpenMobileDrawer}
        placement="right"
        size="full"
        onClose={handleMobileDrawerToggle}
      >
        <DrawerOverlay>
          <DrawerContent bg="rgba(13, 14, 21, .9)">
            <DrawerCloseButton />
            <DrawerHeader as={Link} to="/" onClick={handleMobileDrawerToggle}>
              <Icon as={BiHome} />
              <span style={{ fontSize: 15, marginLeft: 10 }}>返回首頁</span>
            </DrawerHeader>
            <DrawerBody my={5}>
              <div className={cx("menu-drawer__section")}>
                <span>01</span>
                <div>
                  <Link to="/scenarios" onClick={handleMobileDrawerToggle}>
                    <span>劇本主類別</span>
                  </Link>
                  <Link
                    to="/scenarios/歡樂劇本"
                    onClick={handleMobileDrawerToggle}
                  >
                    <span>歡樂劇本</span>
                  </Link>
                  <Link
                    to="/scenarios/靈異劇本"
                    onClick={handleMobileDrawerToggle}
                  >
                    <span>靈異劇本</span>
                  </Link>
                  <Link
                    to="/scenarios/推理劇本"
                    onClick={handleMobileDrawerToggle}
                  >
                    <span>推理劇本</span>
                  </Link>
                  <Link
                    to="/scenarios/情感劇本"
                    onClick={handleMobileDrawerToggle}
                  >
                    <span>情感劇本</span>
                  </Link>
                  <Link
                    to="/scenarios/求生劇本"
                    onClick={handleMobileDrawerToggle}
                  >
                    <span>求生劇本</span>
                  </Link>
                </div>
              </div>
              <div className={cx("menu-drawer__section")}>
                <span>02</span>
                <div>
                  <Link to="/post/group" onClick={handleMobileDrawerToggle}>
                    <span>團體方案</span>
                  </Link>
                </div>
              </div>
              <div className={cx("menu-drawer__section")}>
                <span>03</span>
                <div>
                  <Link to="/post/contact" onClick={handleMobileDrawerToggle}>
                    <span>聯絡我們</span>
                  </Link>
                </div>
              </div>
              <div className={cx("menu-drawer__section")}>
                <span>04</span>
                <div>
                  <Link to="/post/news" onClick={handleMobileDrawerToggle}>
                    <span>最新消息</span>
                  </Link>
                </div>
              </div>
              <div className={cx("menu-drawer__section")}>
                <span>05</span>
                <div>
                  <Link to="/post/qanda" onClick={handleMobileDrawerToggle}>
                    <span>Q&A</span>
                  </Link>
                </div>
              </div>
              <div className={cx("menu-drawer__section")}>
                <span>06</span>
                <div>
                  <Link to="/reservation" onClick={handleMobileDrawerToggle}>
                    <span>訂單查詢</span>
                  </Link>
                </div>
              </div>
              <div className={cx("menu-drawer__info-section")}>
                <div className={cx("menu-drawer__section", "--info")}>
                  <Icon as={HiOutlineMail} mt={1} />
                  <div>
                    <p className={cx("menu-drawer__section-heading")}>
                      聯絡信箱
                    </p>
                    <p>murderwesley@gmail.com</p>
                  </div>
                </div>
                <div className={cx("menu-drawer__section", "--info")}>
                  <Icon as={GoClock} mt={1} />
                  <div>
                    <p className={cx("menu-drawer__section-heading")}>
                      營業時間
                    </p>
                    <p>每週一 ～ 日｜10:00 ～ 21:00</p>
                  </div>
                </div>
                <div className={cx("menu-drawer__section", "--info")}>
                  <Icon as={BiHome} mt={1} />
                  <div>
                    <p className={cx("menu-drawer__section-heading")}>地址</p>
                  </div>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }

  return (
    <div className={cx("nav-bar")} ref={navBarRef}>
      <div className={cx("nav-bar-inner")}>
        <div className={cx("nav-bar-inner__left")}>
          <div className={cx("nav-bar-inner__logo-container")}>
            <Link className={cx("nav-bar-inner__logo")} to="/" />
          </div>
          {!isMobile && (
            <div className={cx("nav-bar-inner__items-container")}>
              <Link to="/scenarios" className={cx("nav-link", "scenarios")}>
                <span>劇本主題</span>
              </Link>
              <Link to="/post/group" className={cx("nav-link")}>
                <span>團體方案</span>
              </Link>
              <Link to="/post/contact" className={cx("nav-link")}>
                <span>聯絡我們</span>
              </Link>
              <Link to="/post/news" className={cx("nav-link")}>
                <span>最新消息</span>
              </Link>
              <Link to="/post/qanda" className={cx("nav-link")}>
                <span>Ｑ＆Ａ</span>
              </Link>
            </div>
          )}
        </div>
        <div className={cx("nav-bar-inner__right")}>
          {isMobile ? (
            <div
              className={cx("nav-bar__mobile-menu-button")}
              onClick={handleMobileDrawerToggle}
            >
              <HamburgerIcon w={6} h={6} />
            </div>
          ) : (
            <Link to="/reservation" className={cx("nav-link")}>
              <span>訂單查詢</span>
            </Link>
          )}
        </div>
      </div>
      {isOpenMobileDrawer && renderMobileDrawer()}
    </div>
  );
}; 

export default Navbar;
