import React from "react";
import classNames from "classnames";
import style from "./style.css";

const cx = classNames.bind(style);

const LoadingSpinner = () => {
  return (
    <div className={cx("loading-spinner-container")}>
      <div className={cx("sk-chase", "loading-spinner")}>
        <div className={cx("sk-chase-dot")} />
        <div className={cx("sk-chase-dot")} />
        <div className={cx("sk-chase-dot")} />
        <div className={cx("sk-chase-dot")} />
        <div className={cx("sk-chase-dot")} />
        <div className={cx("sk-chase-dot")} />
      </div>
    </div>
  );
};

export default LoadingSpinner;