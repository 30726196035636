import React from "react";

const Template = () => {
  return (
    <>
      <li>警告：未滿十八歲請勿預約此劇本。</li>
      <li>
        <strong>此劇本遊戲內容因</strong>
        含有血腥、色情、暴力等元素，故限制<strong>年滿十八歲</strong>
        以上之玩家才可預訂。未滿十八歲之玩家請勿預約，謝謝。
      </li>
      <li>
        遊戲現場會查核參與玩家的年齡，請準備具出生年月日之
        <strong>證件</strong>。
        <strong>經查證未滿十八歲之玩家將不予入場。</strong>
        若因此造成遊戲無法進行，
        <strong>恕訂金不予退還，亦不得延期。</strong>
      </li>
    </>
  )
};

export default Template;
