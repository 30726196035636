import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Stack,
  Radio,
  RadioGroup,
  Input,
  InputLeftElement,
  InputGroup,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";

import Context from "../../frontend/context";
import { Footer, LoadingSpinner } from "../";
import style from "./style.scss";

const cx = classNames.bind(style);

const phoneReg = /^09\d{8}$/;
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const paymentText = {
  paid: "訂單已付款",
  pending: "等待付款",
  cancelled_by_timeout: "逾期取消訂單",
}

const Reservation = () => {
  const { isMobile, getData } = useContext(Context);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [type, setType] = useState("email");
  const [input, setInput] = useState("");
  const [isFormatInvalid, setIsFormatInvalid] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [results, setResults] = useState([]);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [hasRequestError, setHasRequestError] = useState(false);

  const isTypeEmail = type === "email";
  const isAbleToSearch = isTypeEmail ? emailReg.test(input) : phoneReg.test(input);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      setInput("");
      setIsFormatInvalid(false);
      setResults([]);
      setHasSearched(false);
    };
  }, [])

  const handleTypeChange = value => {
    setType(value);
    setInput("");
    setIsFormatInvalid(false);
  };

  const handleInputChange = e => {
    setInput(e.target.value);
    setIsFormatInvalid(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (hasSearched) {
      // 按下再次查詢，清除查詢結果與輸入欄位
      setInput("");
      setResults([]);
      setHasSearched(false);
      return;
    } else if (!isAbleToSearch) {
      // Email 或電話號碼 格式不符
      setIsFormatInvalid(true);
      return;
    }

    setHasRequestError(false);
    setIsFetchingData(true);

    getData(`/reservations/search?uid=${input}`)
      .then(res => res.json())
      .then(res => {
        setResults(res.result);
      })
      .catch(err => {
        console.log(err);
        setHasRequestError(true);
      })
      .finally(() => {
        setIsFetchingData(false);
        setHasSearched(true)
      });
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
  }

  const renderInputFields = () => {
    return (
      <div>
        <RadioGroup onChange={handleTypeChange} value={type} pb="4">
          <Stack direction="row">
            <Radio colorScheme="orange" value="email" size="lg">
              Email
            </Radio>
            <Radio colorScheme="orange" value="phone" size="lg">
              電話號碼
            </Radio>
          </Stack>
        </RadioGroup>
        <Stack direction="column" pb="10">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              boxSize={isMobile ? "40px" : "48px"}
              children={
                isTypeEmail ? (
                  <EmailIcon color="gray.300" />
                ) : (
                  <PhoneIcon color="gray.300" />
                )
              }
            />
            <Input
              size={isMobile ? "md" : "lg"}
              isInvalid={isFormatInvalid}
              errorBorderColor="crimson"
              type={isTypeEmail ? "email" : "tel"}
              placeholder={
                isTypeEmail ? "請填入預約者的 Email" : "請填入預約者的電話號碼"
              }
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </InputGroup>
          {isFormatInvalid && (
            <p>請輸入有效的{isTypeEmail ? " Email " : "電話號碼"}</p>
          )}
          {hasRequestError && (
            <p>目前無法完成查詢，請稍後再試或者直接前往 SimplyBook 預約系統查詢。</p>
          )}
        </Stack>
      </div>
    );
  }

  const renderResults = () => {
    if(results.length === 0) {
      return <p className={cx("no-reservations")}>查無資料</p>
    };

    const { name, email, phone } = results[0];
    return (
      <>
        <div className={cx("reservation-user-data-container")}>
          <p>{name} 您好，以下是您的預約資料：</p>
          <p>Email:  {email}</p>
          <p>電話號碼： {phone}</p>
        </div>
        <table className={cx("reservation-data-table")}>
          <thead>
            <tr>
              <td>劇本</td>
              <td>時段</td>
              <td>分店</td>
              <td>預約狀態</td>
            </tr>
          </thead>
          <tbody>
            {results.map((row, idx) => {
              const { scene_name, scene_slug, start_date, branch, payment_status} = row;

              return (
                <tr key={`${scene_name}-${idx}`}>
                  <td>
                    <Link to={`/scenario/${scene_slug}`}>{scene_name}</Link>
                  </td>
                  <td>{start_date.substring(0, start_date.length -3)}</td>
                  <td>{branch ? branch[0] : ""}</td>
                  <td>{paymentText[payment_status] || payment_status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className={cx("reservation-page")}>
      <Helmet>
        <title>訂單查詢 | 謀殺衛斯理 Mwlarp.com</title>
      </Helmet>
      {isFetchingData && <LoadingSpinner />}
      {isShowDialog && <CancelDialog isMobile={isMobile} onClose={handleCloseDialog}/>}
      <div className={cx("reservation-page-inner")}>
        <h3>訂單查詢</h3>
        {hasSearched ? renderResults() : renderInputFields()}
        <div>
          <button
            className={cx("rsv-action-button", { active: isAbleToSearch })}
            onClick={handleSearch}
          >
            {hasSearched ? "再次查詢" : "查詢"}
          </button>
        </div>
        <p className={cx("rsv-cancel-link")} onClick={() => setIsShowDialog(true)}>
          取消訂單請點此
        </p>
      </div>
      <Footer isMobile={isMobile} />
    </div>
  );
};

const CancelDialog = ({ isMobile, onClose }) => {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      autoFocus={false}
      closeOnOverlayClick={true}
      isCentered
    >
      <ModalOverlay />
      <ModalContent pt={5} mx={4} borderRadius="19px" >
        <ModalBody
          textAlign="center"
          fontSize={isMobile ? "16px" : "20px"}
          color="#0A0A0A"
          borderBottomWidth="1px"
          borderColor="#E3E6E6"
        >
          <p>
            要再想想嗎？<br/>已付訂金的場次取消後<strong>不會退還款項</strong>喔！
          </p>
          <Icon as={FiAlertTriangle} color="#B3390E" boxSize={isMobile ? "40px" : "80px"} />
          <p style={{ marginBottom: 20 }}>想<span style={{ color: "#B3390E" }}>修改時間</span>想<span style={{ color: "#B3390E" }}>更換劇本</span>？<br/>請別取消！私訊粉絲專頁由專人幫您服務</p>
          <a href="https://www.facebook.com/larpwesley/" target="_blank">
            <button className={cx("modal-button", "facebook")}>
              Facebook粉絲專頁
            </button>
          </a>
        </ModalBody>
        <ModalFooter 
          flexDirection="column"
          fontSize={isMobile ? "16px" : "20px"}
          color="#0A0A0A"
        >
          <p>訂金<span style={{ color: "#B3390E" }}>付款失敗？</span></p>
          <p style={{ marginBottom: 20 }}>
            點擊下方連結，取消後重新訂位
          </p>
          <a href="https://larpwesley.simplybook.asia/v2/#client/sign-in" target="_blank">
            <button className={cx("modal-button", "agree")}>
              確認前往取消頁面
            </button>
          </a>
          <button className={cx("modal-button")} onClick={onClose}>
            回上一頁
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Reservation;