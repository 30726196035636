const branchDetail = {
  ns: {
    name: "寧夏館",
    address: "台北市大同區寧夏路79號B1",
    gMapUrl: "https://g.page/mwlarp?share",
    tel: ["02-25571225"],
    openingHours: ["每週一 ～ 日｜10:00 ～ 22:00"],
  },
  ts: {
    name: "天祥館",
    address: "台北市中山區天祥路35號B1",
    gMapUrl: "https://g.page/larpwesley?share",
    tel: ["02-25221433"],
    openingHours: ["每週一 ～ 日｜10:00 ～ 22:00"],
  },
  my: {
    name: "明曜館",
    address: "台北市大安區忠孝東路四段176-1號10樓",
    gMapUrl: "https://goo.gl/maps/TMQHYS84CgqkKHFb9",
    tel: ["0916-141-943", "0919-525-650"],
    openingHours: ["每週一 ～ 日｜10:00 ～ 22:00"],
  },
  cy: {
    name: "中原館",
    address: "桃園市中壢區大仁一街5號",
    gMapUrl: "https://goo.gl/maps/rxDkHqCxRyC8L5QKA",
    tel: ["03-4361246"],
    openingHours: ["每週一 ～ 日｜10:00 ～ 22:00"],
  },
  ol: {
    name: "線上館",
    address: "",
    gMapUrl: "",
    tel: "02-25221433",
    openingHours: ["每週一 ～ 日｜10:00 ～ 22:00"],
  }
}

const offlineBranchKeys = ["ts", "my", "cy"];
const branchKeys = Object.keys(branchDetail);

export {
  offlineBranchKeys,
  branchKeys,
  branchDetail
}