import React from "react";

const Template = () => {
  return (
    <ul>
      <li><strong>謀殺衛斯理 場地租借規範</strong></li>
      <li>一、週一至週五之無使用的包廂，時段須配合該包廂劇本排程時段來調整。</li>
      <li>二、訂場方式與價格：<br/>
        1.透過官網訂購，付款訂金1000元才算完成訂位。<br/>
        2.可使用時間為 官網顯示時間，若有其他需求，可私訊 謀殺衛斯理工作室 facebook粉絲專頁。
      </li>
      <li>三、場地租借規則：<br/>
        1.本館未提供餐飲代訂服務。<br/>
        2.場地佈置或借用物品請事先私訊 謀殺衛斯理工作室 facebook粉絲專頁，當天不受理額外道具租借，使用完畢應負責恢復原狀或歸還。<br/>
        3.本館全館全面禁煙，禁止飲酒。<br/>
        4.若需張貼海報／文宣，請使用無痕膠帶／環保黏土張貼海報宣傳品。<br/>
        5.活動進行時請控制音量，不得影響他人使用權益，必要時本館得逕行終止場地租借，且費用不予返還。<br/>
        6.空間使用完畢需將環境整理乾淨，可攜帶外食，若環境髒亂（如打翻飲料等）將酌收 500 元清潔費。
      </li>
    </ul>
  )
};

export default Template;
