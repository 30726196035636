import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { GoLocation  } from "react-icons/go";
import classNames from "classnames";
import style from "./style.scss";
import { offlineBranchKeys, branchDetail } from '../staticData';

const cx = classNames.bind(style);

const Footer = (props) => {
  const { isMobile } = props;

  return (
    <div className={cx("footer")}>
      <div className={cx("footer-inner")}>
        <div className={cx("footer-content-container")}>
          <div className={cx("footer__logo-container")}>
            <Link className={cx("footer__logo")} to="/" />
          </div>
        </div>
        <div className={cx("footer-content-container")}>
          <h6>導覽</h6>
          <div className={cx("footer-content-inner__column-container")}>
            <div className={cx("footer-content-inner__column")}>
              <a href="/">首頁</a>
              <a href="/post/news">最新消息</a>
              <a href="/scenarios">劇本主題</a>
              <a href="/post/about">關於我們</a>
            </div>
            <div className={cx("footer-content-inner__column")}>
              <a href="/post/group">團體方案</a>
              <a href="/post/hiring">徵人公告</a>
              <a href="/post/qanda">Q&A</a>
            </div>
          </div>
        </div>
        <div className={cx("footer-content-container")}>
          <h6>聯絡我們</h6>
          <div className={cx("footer-content-inner__row")}>
            {offlineBranchKeys.map(branchKey => {
              const { name, gMapUrl, tel} = branchDetail[branchKey];
              return (
                <div className={cx("footer-contact-store")} key={name}>
                  <p>
                    <span>{name}</span>
                    <a href={gMapUrl} target="_blank">
                      <Icon as={GoLocation} ml={2} />
                    </a>
                  </p>
                  {tel.map(t => (
                    <p key={`footer-${branchKey}-${t}`}>
                      {t}
                    </p>
                  ))}
                </div>
              )
            })}
          </div>
          <div className={cx("footer-content-inner__row")}>
            <div className={cx("footer-contact-email")}>
              <p>連絡信箱</p>
              <a>murderwesley@gmail.com</a>
            </div>
          </div>
        </div>
      </div>
      <div className={cx("footer-bottom")}>
        <span>© 謀殺衛斯理工作室 CO. LTD 版權所有</span>
        <span>
          WEBSITE DESIGNED BY <a href="https://escape.bar">ESCAPE.BAR</a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
