import React, { useState, useRef } from "react";
import { useOutsideClick } from "@chakra-ui/react";
import classNames from "classnames";
import style from "./style.scss";

const cx = classNames.bind(style);

const messengerLinkMap = {
  ts: "https://m.me/larpwesley/",
  my: "https://m.me/mylarpwesley/",
  cy: "https://m.me/zylarpwesley/"
}

const Messenger = () => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick({
    ref: ref,
    handler: () => setIsSelectorOpen(false),
  });

  const handleTargetSelect = (key) => {
    window.open(messengerLinkMap[key], "_blank")
  };

    return (
      <div ref={ref} className={cx("messenger-selector-container")}>
        {isSelectorOpen && (
          <div className={cx("messeneger-selector-buttons")}>
            <button onClick={() => handleTargetSelect("ts")}>
              台北天祥館
            </button>
            <button onClick={() => handleTargetSelect("my")}>
              台北明曜館
            </button>
            <button onClick={() => handleTargetSelect("cy")}>
              桃園中原館
            </button>
          </div>
        )}
        <div
          className={cx("messeneger-selector-image")}
          onClick={() => setIsSelectorOpen(true)}
        />
        <span>聯繫客服</span>
      </div>
    );

};

export default Messenger;