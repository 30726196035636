import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import Context from "../../frontend/context";
import { Footer, LoadingSpinner } from "../";
import style from "./style.scss";

const cx = classNames.bind(style);

const Post = () => {
  const { isMobile, getData } = useContext(Context);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [title, setTitle] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [content ,setContent] = useState(null);
  const { slug } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      setTitle("");
      setPublishDate("");
      setContent(null);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    setIsFetchingData(true);

    getData(`/posts/${slug}`)
      .then((res) => {
        if (res.status === 404) {
          history.push("/oops");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        const { title, publishDate, content } = data;

        setTitle(title);
        setPublishDate(publishDate);
        setContent(content);

        setIsFetchingData(false);
      })
      .catch((err) => {
        console.log(err)
        history.push("/oops");
      });

  }, [slug]);

  const createMarkup = () => {
    return { __html: content }
  }

  return (
    <div className={cx("post-page")}>
      <Helmet>
        <title>{title} | 謀殺衛斯理 Mwlarp.com</title>
      </Helmet>
      {isFetchingData && <LoadingSpinner />}
      <div className={cx("post-page-inner")}>
        <h1 className={cx("post-page-title")}>{title}</h1>
        <h4 className={cx("post-page-date")}>發表日期：{publishDate}</h4>
        <div
          className={cx("post-page-content")}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default Post;