import React, { Fragment, useState, useEffect, useContext, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Avatar,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { GoLocation, GoClock } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { BsPerson, BsPuzzle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from 'moment';
import classNames from "classnames";

import Context from "../../frontend/context";
import { offlineBranchKeys, branchDetail } from '../staticData'
import { Footer, ScenarioCard, LoadingSpinner } from "../";
import NoticeTemplateRental from './NoticeTemplateRental';
import NoticeTemplateOffline from './NoticeTemplateOffline';
import NoticeTemplateOnline from './NoticeTemplateOnline';
import NoticeTemplateEighteen from './NoticeTemplateEighteen';
import NoticeTemplateHaunted from './NoticeTemplateHaunted'
import style from "./style.scss";

const cx = classNames.bind(style);

const sceneTypeDict = {
  ONLINE: "online",
  RENTAL: "rental",
  CUSTOM: "custom"
};

// const scenarioApiRoute = `https://api-staging.mwlarp.com/v1/scenes`;

export const initialScenarioDetailState = {
  name: "",
  mainCategory: "",
  categories: [],
  hashtags: [],
  price: 0,
  priceUnit: "人",
  bannerUrl: "",
  moreImages: [], 
  minPlayer: 0,
  maxPlayer: 0,
  difficulty: "",
  duration: 0,
  content: "",
  characters: [],
  stores: [],
  faqs: [],
  articles: [],
  associatedScenes: [],
  isLatest: false,
  isREighteen: false,
  isHaunted: false,
  sceneType: null,
  customized_info: null,
  notice_message: null
};

const Scenario = () => {
  const { isMobile, getData } = useContext(Context);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [scenarioDetail, setScenarioDetail] = useState(initialScenarioDetailState);
  const [isShowAlertDialog, setIsShowAlertDialog]= useState(false);
  const [isShowLightbox, setIsShowlightbox] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { slug } = useParams();
  const history = useHistory();
  const {
    name,
    mainCategory,
    categories, // 有需要？
    hashtags,
    price,
    priceUnit,
    bannerUrl,
    moreImages,
    minPlayer,
    maxPlayer,
    difficulty,
    duration,
    content,
    characters,
    stores,
    faqs,
    articles,
    associatedScenes,
    isLatest,
    isREighteen,
    isHaunted,
    sceneType,
    customized_info,
    notice_message
  } = scenarioDetail;
  const isOnline = useMemo(() => {
    return stores.includes('線上館') || sceneType === sceneTypeDict.ONLINE
  }, [scenarioDetail]);

  const isRental = useMemo(() => {
    return sceneType === sceneTypeDict.RENTAL
  }, [scenarioDetail])

  const isCustom = useMemo(() => {
    return sceneType === sceneTypeDict.CUSTOM
  }, [scenarioDetail]);

  useEffect(() => {
    return () => {
      setScenarioDetail(initialScenarioDetailState);
      setImageIndex(0);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (name !== slug) getScenarioData();
  }, [slug]);

  useEffect(() => {
    const hasOver18 = sessionStorage.getItem("hasOver18");
    let hasCheckedOver18 = false;

    if (hasOver18) {
      const checkedScenarioNames = JSON.parse(hasOver18);
      hasCheckedOver18 = checkedScenarioNames.includes(slug);
    }
    
    if ((isREighteen || isHaunted) && !hasCheckedOver18) {
      setIsShowAlertDialog(true);
    }
  }, [isREighteen, isHaunted, slug]);

  const getScenarioData = () => {
    setIsFetchingData(true);

    getData(`/scenes/${slug}`)
      .then((res) => {
        if (res.status === 404) {
          history.push("/oops");
        } else {
          return res.json();
        }
      })
      .then((newData) => {
        setScenarioDetail((prevState) => {
          return { ...prevState, ...newData };
        });
        setIsFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        history.push("/oops");
      });
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleCloseAlertDialog = () => {
    const hasOver18 = sessionStorage.getItem("hasOver18");
    let prevHasOver18 = [];

    if (hasOver18) {
      prevHasOver18 = JSON.parse(hasOver18);
    }

    const newHasOver18 = JSON.stringify([...prevHasOver18, slug]);

    sessionStorage.setItem("hasOver18", newHasOver18);

    setIsShowAlertDialog(false);
  };

  const handleReturn = () => {
    if (window.history.length < 3) {
      history.push("/scenarios");
    } else {
      window.history.go(-1);
    }
  };

  const handleMoreImageClick = (index) => {
    setImageIndex(index);
    setIsShowlightbox(true);
  };

  const handleImageIndexChange = (type) => {
    if (type === "NEXT") {
      setImageIndex((imageIndex + 1) % moreImages.length);
    } else {
      setImageIndex((imageIndex + moreImages.length - 1) % moreImages.length);
    }
  };

  const renderIntro = () => {
    return (
      <div className={cx("scenario-page__content-container")}>
        <h3>{isRental || isCustom ? "商品介紹" : "前情提要"}</h3>
        <p dangerouslySetInnerHTML={createMarkup(content)}></p>
        {!isMobile && (
          <div>
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              breakpoints={{
                960: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              navigation
              onSlideChange={(swiper) => console.log(swiper.activeIndex)}
            >
              {moreImages.map((imageObj, idx) => (
                <SwiperSlide key={`more-image-${idx}`}>
                  <div
                    className={cx("content__images-container")}
                    style={
                      imageObj.imageUrl
                        ? {
                            backgroundImage: `url(${imageObj.imageUrl})`,
                          }
                        : {}
                    }
                    onClick={() => handleMoreImageClick(idx)}
                  ></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    );
  };

  const getCharacter = data => {
    const { name, avatarUrl, introduction } = data;

    return (
      <div className={cx("character")} key={name}>
        <div className={cx("character__avatar")}>
          <Avatar
            size={isMobile ? "lg" : "xl"}
            src={avatarUrl ? avatarUrl : ""}
          />
        </div>
        <div className={cx("character__intro")}>
          <h6>{name}</h6>
          <p>
            {introduction || "角色介紹"}
          </p>
        </div>
      </div>
    );
  };

  const renderCharacters = () => {
    if (characters.length === 0) return;

    return (
      <div className={cx("scenario-page__content-container")}>
        <h3>角色設定</h3>
        <div className={cx("characters-container")}>
          {characters.map((chObj, i) => getCharacter(chObj))}
        </div>
      </div>
    );
  };

  const getStoreDetail = (storeName) => {
    let name = "", gMapUrl = "", address = "", openingHours = "", tel = "";

    if (storeName === sceneTypeDict.CUSTOM && customized_info !== null) {
      name = customized_info.address_name;
      address = customized_info.address;
      openingHours = customized_info.opening_hour;
      tel = customized_info.tel;
    } else {
      let key = '';
      switch (storeName) {
        case "明曜館":
          key = 'my';
          break;
        case "中原館":
          key = 'cy';
          break;
        case "線上館":
          key = 'ol';
          break;
        case "天祥館":
        default:
          key = 'ts';
          break;
      }
  
      const targetBranch = branchDetail[key];
      name = targetBranch.name;
      gMapUrl = targetBranch.gMapUrl;
      address = targetBranch.address;
      openingHours = targetBranch.openingHours[0];
      tel = targetBranch.tel[0];
    }

    return (
      <div className={cx("store")} key={name}>
        <h6>{name}</h6>
        {address && (
          <p>
            <Icon as={GoLocation} mt={1} />
              <a
                href={gMapUrl ? gMapUrl : `https://maps.google.com/maps?q=${address}`}
                target="_blank"
                className={cx("address")}
              >
                {address}
              </a>
          </p>
        )}
        {openingHours && (
          <p>
            <Icon as={GoClock} mt={1} />
            <span>{openingHours}</span>
          </p>
        )}
        {tel && (
          <p>
            <Icon as={BiPhoneCall} mt={1} />
            <span>{tel}</span>
          </p>
        )}
      </div>
    );
  };

  const renderStores = () => {
    if (stores.length === 0 && !isCustom || (isCustom && customized_info === null)) return;

    return (
      <div
        className={cx("scenario-page__content-container", {
          "has-mobile-margin": isMobile,
        })}
      >
        <h3>遊戲地點</h3>
        <div className={cx("stores-container")}>
          {isCustom 
            ? getStoreDetail(sceneTypeDict.CUSTOM)
            : stores.map(storeName => getStoreDetail(storeName))
          }
        </div>
      </div>
    );
  };

  const getNoticeContent = () => {
    if (notice_message) {
      return <div dangerouslySetInnerHTML={createMarkup(notice_message)} />
    } else if (isRental) {
      return <NoticeTemplateRental />
    } else if (isOnline) {
      return (
        <ol>
          {isREighteen && <NoticeTemplateEighteen />}
          {isHaunted && <NoticeTemplateHaunted />}
          <NoticeTemplateOnline />
        </ol>
      )
    } else {
      return (
        <ol>
          {isREighteen && <NoticeTemplateEighteen />}
          {isHaunted && <NoticeTemplateHaunted />}
          <NoticeTemplateOffline />
        </ol>
      )
    }
  }

  const renderNotice = () => {
    return (
      <div className={cx("scenario-page__content-container")}>
        <h3>注意事項</h3>
        <div className={cx("notice-container")}>
          {getNoticeContent()}
        </div>
      </div>
    );
  };

  const renderFaq = () => {
    if (faqs.length === 0) return;

    const faq = ({ data, idx, isLast }) => {
      const { question, answer } = data;
      return (
        <AccordionItem mb={isLast ? 0 : 4} border="none" key={`faq-${idx}`}>
          <AccordionButton
            py={isMobile ? 5 : 7}
            px={isMobile ? 5 : 8}
            bg="#FFFFFF"
            color="#000000"
            borderRadius={isMobile ? "8px" : "15px"}
            _hover={{
              bg: "#FFFFFF",
            }}
            _expanded={
              isMobile
                ? {
                    borderRadius: "8px 8px 0 0",
                  }
                : {
                    borderRadius: "15px 15px 0 0",
                  }
            }
          >
            <Box flex="1" textAlign="left">
              <p className={cx("scenario-page__faq-title")}>{question}</p>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            py={isMobile ? 4 : "30px"}
            px={isMobile ? 5 : "56px"}
            bg="#F0F3FA"
            color="#3D3B3B"
            borderBottomRightRadius={isMobile ? "8px" : "15px"}
            borderBottomLeftRadius={isMobile ? "8px" : "15px"}
          >
            {answer}
          </AccordionPanel>
        </AccordionItem>
      );
    };

    return (
      <div className={cx("scenario-page__normal-container")}>
        <h3 className={cx("scenario-page__normal-heading")}>常見問題</h3>
        <div className={cx("faq-containier")}>
          <Accordion allowMultiple>
            {faqs.map((faqObj, i) => {
              return faq({ data: faqObj, idx: i, isLast: i === faqs.length - 1 });
            })}
          </Accordion>
        </div>
      </div>
    );
  }; 

  const renderOutboundLinks = () => {
    if (articles.length === 0) return;

    const card = data => {
      const { title, imageUrl, date, description, url } = data;
      // TODO: utm_source=...

      return (
        <a
          className={cx("outbound-link-card")}
          href={url || "#"}
          target="_blank"
          key={`art-${title}`}
        >
          <div
            className={cx("outbound-link-card__image-container")}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          ></div>
          <p className={cx("outbound-link-card__date")}>
            {date && moment(date).format("YYYY/MM/DD")}
          </p>
          <p className={cx("outbound-link-card__title")}>
            {title || "這是文章標題不超過兩行"}
          </p>
        </a>
      );
    }

    return (
      <div className={cx("scenario-page__normal-container")}>
        <h3 className={cx("scenario-page__normal-heading")}>其他文章</h3>
        <div className={cx("outbound-links-container")}>
          {articles.map((articleObj, i) => card(articleObj))}
        </div>
      </div>
    );
  };

  const renderRelatedScenarios = () => {
    if (associatedScenes.length === 0) return;
    
      return (
        <div className={cx("scenario-page__normal-container")}>
          <h3 className={cx("scenario-page__normal-heading")}>其他劇本</h3>
          <div className={cx("related-scenarios-container")}>
            {associatedScenes.map((sceneObj, i) => (
              <Link to={`/scenario/${sceneObj.slug}`} key={sceneObj.id} style={{ marginRight: 20 }} >
                <ScenarioCard idx={i} isMobile={isMobile} {...sceneObj} />
              </Link>
            ))}
          </div>
        </div>
      );
  };

  const renderScenarioCard = () => {
    return (
      <div className={cx("scenario-main-card-container")}>
        <div className={cx("card__main-info")}>
          {isLatest && <div className={cx("card-tag")}>最新</div>}
          <div className={cx("card-difficulty", difficulty)} />
          {isMobile ? (
            <div>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination
                onSlideChange={(swiper) => console.log(swiper.activeIndex)}
              >
                <SwiperSlide>
                  <div
                    className={cx("card-banner-container")}
                    style={{
                      backgroundImage: `url(${bannerUrl}`,
                    }}
                  />
                </SwiperSlide>
                {moreImages.map((imageObj, idx) => (
                  <SwiperSlide key={`more-image-${idx}`}>
                    <div
                      className={cx("card-banner-container")}
                      style={
                        imageObj.imageUrl
                          ? {
                              backgroundImage: `url(${imageObj.imageUrl})`,
                            }
                          : {}
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <div
              className={cx("card-banner-container")}
              style={{
                backgroundImage: `url(${bannerUrl}`,
              }}
            />
          )}
          {!isMobile && <h1>{name}</h1>}
          <div className={cx("card-hashtags")}>
            {hashtags.length ? (
              hashtags.map((tag, idx) => {
                return <span key={`${tag}-${idx}`}>#{tag}</span>;
              })
            ) : (
              <span>#</span>
            )}
          </div>
          {isMobile && <h1>{name}</h1>}
        </div>
        <div className={cx("card__sub-info")}>
          <div>
            <Icon as={GoClock} boxSize={isMobile ? "14px" : "18px"} />
            <span>{duration} min</span>
          </div>
          <div>
            <Icon as={BsPerson} boxSize={isMobile ? "14px" : "18px"} />
            <span>
              {minPlayer === maxPlayer
                ? `${minPlayer} 人`
                : `${minPlayer}~${maxPlayer} 人`}
            </span>
          </div>
          <div>
            <Icon as={BsPuzzle} boxSize={isMobile ? "14px" : "18px"} />
            <span>
              {typeof mainCategory === "string" && mainCategory.substring(0, 2)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCallToAction = () => {
    return (
      <div className={cx("call-to-action-container")}>
        <p>
          NT$ {price} <span>/ {priceUnit}</span>
        </p>
        {isCustom ? (
          <a href={customized_info && customized_info.url ? customized_info.url : "#"} target="_blank">
            <button>立即預約</button>
          </a>
        ) : (
          <Link to={`/order/${slug}`}>
            <button>立即預約</button>
          </Link>
        )}
      </div>
    );
  };

  const renderAlertDialog = () => {
    let bodyText

    if (isREighteen && isHaunted) {
      bodyText = 
        <span>
          部分故事及遊戲過程含有
          <span style={{ color: "#B3390E" }}>限制級情節及驚嚇橋段</span>
        </span>
    } else if (isREighteen) {
      bodyText = 
        <span>
          部分故事含有
          <span style={{ color: "#B3390E" }}>限制級情節</span>
        </span>
    } else {
      bodyText = 
        <span>
          遊戲過程含有
          <span style={{ color: "#B3390E" }}>驚嚇橋段</span>
        </span>
    }

    return (
      <Modal
        isOpen={isShowAlertDialog}
        onClose={handleReturn}
        autoFocus={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent pt={5} mx={4} borderRadius="19px" >
          <ModalCloseButton color="#1D1C20" />
          <ModalBody
            textAlign="center"
            fontSize={isMobile ? "16px" : "24px"}
            color="#0A0A0A"
            borderBottomWidth="1px"
            borderColor="#E3E6E6"
          >
            <Icon as={FiAlertTriangle} color="#B3390E" boxSize={isMobile ? "40px" : "60px"} />
            <h6
              style={{
                margin: isMobile ? "12px auto 25px": "22px auto 30px",
                color: "#B3390E",
                fontWeight: 900,
              }}
            >
              警告
            </h6>
            <p style={{ marginBottom: isMobile ? "29px" : "34px" }}>
              此劇本
              { bodyText }
              ，請<strong>年滿十八歲之參與玩家需</strong>斟酌考慮適應程度後，再行預訂。
            </p>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <button
              className={cx("modal-button", "agree")}
              onClick={handleCloseAlertDialog}
            >
              同意
            </button>
            <button className={cx("modal-button")} onClick={handleReturn}>
              回上一頁
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <div className={cx("scenario-page")}>
      <Helmet>
        <title>{name} | 謀殺衛斯理 Mwlarp.com</title>
      </Helmet>
      {isShowAlertDialog && renderAlertDialog()}
      {isFetchingData && <LoadingSpinner />}
      {isShowLightbox && (
        <Lightbox
          mainSrc={moreImages[imageIndex].imageUrl}
          nextSrc={moreImages[(imageIndex + 1) % moreImages.length].imageUrl}
          prevSrc={
            moreImages[(imageIndex + moreImages.length - 1) % moreImages.length]
              .imageUrl
          }
          onCloseRequest={() => setIsShowlightbox(false)}
          onMovePrevRequest={() => handleImageIndexChange("PREV")}
          onMoveNextRequest={() => handleImageIndexChange("NEXT")}
          enableZoom={false}
        />
      )}
      <div className={cx("scenario-page-inner")}>
        <div className={cx("scenario-page-breadscrum")}>
          <Breadcrumb
            ml="16px"
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/">
                首頁
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/scenarios">
                劇本篩選頁
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{name}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        {isMobile && renderScenarioCard()}
        <div className={cx("scenario-page-primary")}>
          <div className={cx("scenario-page-primary__main-container")}>
            {renderIntro()}
            {renderCharacters()}
            {renderStores()}
            {renderNotice()}
            {renderFaq()}
          </div>
          {!isMobile && (
            <div className={cx("scenario-page-primary__cta-container")}>
              <div className={cx("sticky-container")}>
                {renderScenarioCard()}
                {renderCallToAction()}
              </div>
            </div>
          )}
        </div>
        <div className={cx("scenario-page-secondary")}>
          {renderOutboundLinks()}
          {renderRelatedScenarios()}
        </div>
      </div>
      {isMobile && renderCallToAction()}
      <Footer isMobile={isMobile} />
    </div>
  );
};

export default Scenario;
