import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Footer } from "../";
import style from "./style.scss";

const cx = classNames.bind(style);

const NotFound = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cx("not-found-page")}>
      <div className={cx("not-found-page-inner")}>
        <div className={cx("not-found-image-container")}>
          <img src={`/images/404.png`}/>
        </div>
        <p className={cx("not-found-desc")}>噢不，這個頁面消失了</p>
        <Link to="/">
          <button className={cx("back-home-button")}>回到首頁</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;