import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import qs from 'query-string';
import {
  Checkbox,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Icon,
  VStack,
  HStack,
  Box,
  Flex,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  SearchIcon,
  ArrowForwardIcon,
  QuestionIcon,
} from "@chakra-ui/icons";
import { BsPerson } from "react-icons/bs";
import { BiFilterAlt, BiDotsVerticalRounded } from "react-icons/bi";
import { DayPickerRangeController } from "react-dates";
import moment from 'moment';
import classNames from "classnames";

import Context from "../../frontend/context";

import { Footer, ScenarioCard, LoadingSpinner } from "../";
import style from "./style.scss";

const cx = classNames.bind(style);


const avaialblePlayerList = [4, 5, 6, 7, 8, 9, 10];
const categoryConditionOptions = [
  { value: "歡樂劇本", text: "歡樂" },
  { value: "靈異劇本", text: "靈異" },
  { value: "推理劇本", text: "推理" },
  { value: "情感劇本", text: "情感" },
  { value: "求生劇本", text: "求生" },
];
const difficultyConditionOptions = [
  { value: "easy", text: "簡單" },
  { value: "mid", text: "中等" },
  { value: "hard", text: "進階" },
];
const storesConditionOptions = [
  { value: "天祥館", text: "天祥館" },
  { value: "明曜館", text: "明曜館" },
  { value: "中原館", text: "中原館" },
  { value: "線上館", text: "線上館" },
  { value: "特約合作", text: "特約合作" },
];
const periodConditionOptions = [
  { value: "morning", text: "早上" },
  { value: "afternoon", text: "下午" },
  { value: "evening", text: "晚上" },
];
const otherConditionOptions = [
  { value: "isReversable", text: "可以反串" },
  { value: "isDressFree", text: "無需換裝" },
];

const START_DATE_STR = "startDate";
const END_DATE_STR = "endDate"
const DATE_FORMAT = "YYYY-MM-DD";
const CURRENT_DATE_OBJ = moment();
const MIN_DATE_OBJ = moment().add(1, 'days');
const FIRST_DATE = moment().add(1, "days").format(DATE_FORMAT);
const MAX_DATE_OBJ = moment().add(30, 'days');
const END_DATE = moment().add(30, "days").format(DATE_FORMAT);
const BEFORE_AFTERNOON_TIME = "12:00:00";
const AFTER_AFTERNOON_TIME = "18:00:00";

const Scenarios = () => {
  const {
    isMobile,
    getData,
    scenariosDataInStore,
    setScenariosDataInStore,
  } = useContext(Context);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [scenariosData, setScenariosData] = useState(null);
  const [showingScenarios, setShowingScenarios] = useState([]);
  const [isPlayerSelectorOpen, setIsPlayerSelectorOpen] = useState(false);
  const [playerAmount, setPlayerAmount] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [focusedInputDate, setFocusedInputDate] = useState(START_DATE_STR);
  const [keywordInput, setKeywordInput] = useState('');
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDifficulties, setSelectedDiffculties] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedPeriods, setSelectedPeriods] = useState([]);
  const [selectedOtherConditions, setSelectedOtherConditions] = useState([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const { category } = useParams();
  const history = useHistory();
  const location = useLocation();
  const barRef = useRef(null);
  const topBtnRef = useRef(null);

  const isDateSet = !!dateRange.startDate;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (category) {
      setSelectedCategories([category]);
    }

    if (location.search) {
      const queries = qs.parse(location.search);
      // console.log(queries);
      if (queries.c) {
        const cats = queries.c.split(",");
        setSelectedCategories(cats);
      }

      if (queries.d) {
        const difs = queries.d.split(",");
        setSelectedDiffculties(difs);
      }

      if (queries.p) {
        setPlayerAmount(parseInt(queries.p));
      }

      if (queries.s) {
        const stos = queries.s.split(",");
        setSelectedStores(stos);
      }

      if (queries.t) {
        const tims = queries.t.split(",");
        const startDate = moment(tims[0]);
        const endDate = tims[1] ? moment(tims[1]) : startDate;
        setDateRange({ startDate, endDate });
      }

      if (queries.p) {
        const pers = queries.p.split(",");
        setSelectedPeriods(pers);
      }

      if (queries.o) {
        const oths = queries.o.split(",");
        setSelectedOtherConditions(oths);
      }
    }

    if (scenariosDataInStore.length > 0 && scenariosData === null) {
      setScenariosData(scenariosDataInStore);
    } else {
      setIsFetchingData(true);

      getData(`/scenes`)
        .then((res) => {
          if (res.status === 404) {
            history.push("/oops");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setScenariosData(data.scenes);
          setScenariosDataInStore(data.scenes);
          setIsFetchingData(false);
        })
        .catch((err) => {
          console.log(err);
          history.push("/oops");
        });
    }

    window.addEventListener("scroll", handleScroll);

    return (() => {
      window.removeEventListener("scroll", handleScroll);
    })
  }, []);

  useEffect(() => {
    if (category) {
      setSelectedCategories([category]);
    }
  }, [category]);

  useEffect(() => {
    if (scenariosData === null) return;
    let newShowingScenarios = [...scenariosData];
    // console.log(newShowingScenarios)

    if (playerAmount) {
      newShowingScenarios = newShowingScenarios.filter(
        (scenario) =>
          scenario.maxPlayer >= playerAmount &&
          scenario.minPlayer <= playerAmount
      );
    }

    if (selectedCategories.length) {
      newShowingScenarios = newShowingScenarios.filter((scenario) =>
        scenario.categories.some((category) =>
          selectedCategories.includes(category)
        )
      );
    }

    if (selectedDifficulties.length) {
      newShowingScenarios = newShowingScenarios.filter((scenario) =>
        selectedDifficulties.includes(scenario.difficulty)
      );
    }

    if (selectedStores.length) {
      newShowingScenarios = newShowingScenarios.filter((scenario) =>
        scenario.stores.some((store) => selectedStores.includes(store))
      );
    }

    if (selectedKeyword.length) {
      newShowingScenarios = newShowingScenarios.filter(
        (scenario) =>
          scenario.keywords.indexOf(selectedKeyword) > -1 ||
          scenario.slug.indexOf(selectedKeyword) > -1 ||
          scenario.hashtags.some(tag => tag.includes(selectedKeyword))
      );
    }

    if (selectedOtherConditions.length) {
      if (selectedOtherConditions.includes("isReversable")) {
        newShowingScenarios = newShowingScenarios.filter(
          (scenario) => scenario.isReversable
        );
      }

      if (selectedOtherConditions.includes("isDressFree")) {
        newShowingScenarios = newShowingScenarios.filter(
          (scenario) => scenario.isDressfree
        );
      }
    }

    if (dateRange.startDate && dateRange.endDate) {
      newShowingScenarios = newShowingScenarios.filter((scenario) => {
        const { branches_avaliable: availbleBranches } = scenario;

        // 若已有選擇場館的狀況下，只考慮被選場館的可用時段；否則為所有場館
        const branchKeys = selectedStores.length
          ? selectedStores
          : availbleBranches
            ? Object.keys(availbleBranches)
            : [];

        // 濾出上述場館至少有一天在此指定區間內有空場
        // 理論上到此階段已經濾過所有條件包含場館
        return branchKeys.some((branchKey) => {
          const dateKeys = availbleBranches[branchKey] 
            ? Object.keys(availbleBranches[branchKey]) 
            : [];

          return dateKeys.some((date) => {
            if (
              moment(date).isBetween(
                dateRange.startDate.format(DATE_FORMAT),
                dateRange.endDate.format(DATE_FORMAT),
                undefined,
                "[]"
              )
            ) {
              // 此日期是否在指定區間內，若有則繼續做時段判斷
              const availableIntervals = availbleBranches[branchKey][date];

              if (selectedPeriods.length > 0) {
                // 若有選擇時段，找到至少一個可以的指定時段
                return selectedPeriods.some((period) => {
                  switch (period) {
                    case "morning":
                      return availableIntervals.some((time) =>
                        moment(`${date} ${time}`).isSameOrBefore(
                          `${date} ${BEFORE_AFTERNOON_TIME}`
                        )
                      );
                    case "afternoon":
                      return availableIntervals.some((time) =>
                        moment(`${date} ${time}`).isBetween(
                          `${date} ${BEFORE_AFTERNOON_TIME}`,
                          `${date} ${AFTER_AFTERNOON_TIME}`,
                          undefined,
                          "()"
                        )
                      );
                    case "evening":
                      return availableIntervals.some((time) =>
                        moment(`${date} ${time}`).isSameOrAfter(
                          `${date} ${AFTER_AFTERNOON_TIME}`
                        )
                      );
                  }
                });
              } else {
                // 若無選擇時段，只要有任一時段
                return availableIntervals.length > 0;
              }
            } else {
              // 若此日期不在指定區間內，濾掉
              return false;
            }
          });
        });
      });
    }

    // console.log(newShowingScenarios);

    setShowingScenarios(newShowingScenarios);
  }, [
    scenariosData,
    playerAmount,
    dateRange,
    selectedCategories,
    selectedDifficulties,
    selectedStores,
    selectedPeriods,
    selectedOtherConditions,
    selectedKeyword,
  ]);

  const handleConditionToggle = field => e => {
    const value = e.target.value;

    let targetStates, method, newValue, qKey;
    
    switch (field) {
      case "category":
        targetStates = selectedCategories;
        method = setSelectedCategories;
        qKey = "c";
        break;
      case "difficulty":
        targetStates = selectedDifficulties;
        method = setSelectedDiffculties;
        qKey = "d";
        break;
      case "store":
        targetStates = selectedStores;
        method = setSelectedStores;
        qKey = "s";
        break;
      case "period":
        targetStates = selectedPeriods;
        method = setSelectedPeriods;
        qKey = "p";
        break;
      case "other":
        targetStates = selectedOtherConditions;
        method = setSelectedOtherConditions;
        qKey = "o";
        break;
    }

    newValue = targetStates.includes(value) ? targetStates.filter((item) => item !== value) : [...targetStates, value];
    method(newValue);

    handleUrlQueriesChange(qKey, newValue);
  };

  const handleUrlQueriesChange = (qKey, value) => {
    const queries = qs.parse(location.search);

    const newSearch = {
      ...queries,
      [qKey]: value.join(",")
    }

    history.replace({
      path: location.pathname,
      search: qs.stringify(newSearch),
    });
  };

  const togglePlayerSelectorOpen = isOpen => {
    setIsPlayerSelectorOpen(isOpen);
  };

  const handlePlayerAmountChange = (value) => {
    setPlayerAmount(value);

    handleUrlQueriesChange("p", [value]);
  };

  const handlePlayerAmountReset = () => {
    setPlayerAmount(0);

    handleUrlQueriesChange("p", []);
  };

  const toggleDatePickerOpen = (isOpen) => {
    setIsDatePickerOpen(isOpen);

    if (isOpen) {
      setDateRange({ startDate: null, endDate: null });
    }
  }

  const handleDateRangeChange = ({ startDate, endDate }) => {
    // console.log(startDate)
    // console.log(endDate)
    setDateRange({ startDate: startDate, endDate: endDate });

    const startValue = startDate.format(DATE_FORMAT);
    const endValue = endDate ? endDate.format(DATE_FORMAT) : startValue;
    handleUrlQueriesChange("t", [startValue, endValue]);
  };

  const handleDateFocusedInput = (focusedInput) => {
    // console.log(focusedInput);
    setFocusedInputDate(!focusedInput ? START_DATE_STR : focusedInput);
    if (!focusedInput) toggleDatePickerOpen(false);
  };

  const calculateAvailableDateRange = day => {
    // EXTRACT THIS
    return (
      day.isSameOrBefore(CURRENT_DATE_OBJ) || day.isSameOrAfter(MAX_DATE_OBJ)
    );
  };

  const handleKeywordChange = (e) => {
    // console.log(e)
    setKeywordInput(e.target.value);

    if (isMobile) {
      handleKeywordSet();
    }
  };

  const handleKeywordSet = () => {
    setSelectedKeyword(keywordInput);
  };

  const handleConditionReset = () => {
    setSelectedCategories([]);
    setSelectedDiffculties([]);
    setSelectedStores([]);
    setSelectedPeriods([]);
    setSelectedOtherConditions([]);
    setSelectedKeyword("");
  };

  const handleDrawerOpen = () => {
    setIsFilterDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsFilterDrawerOpen(false);
  };

  const handleScroll = () => {
    const verticalcrollPercent = (window.scrollY - window.innerHeight) / window.innerHeight;

    if (topBtnRef.current) {
      if (verticalcrollPercent >= 0.5) {
        topBtnRef.current.style.display = "block";
      } else {
        topBtnRef.current.style.display = "none";
      }
    }
  }

  const handleToTop = () => {
    window.scrollTo({
      'behavior': 'smooth',
      'top': barRef.current.offsetTop
    })
  };

  const renderCards = () => {
    if (!isFetchingData && showingScenarios.length === 0) {
      return (
        <div className={cx("scenarios--empty")}>
          <div className={cx("empty-image-container")} />
          <p>
            無搜索結果
            <br />
            換個方式搜搜看？
          </p>
        </div>
      );
    }

    return showingScenarios.map((scenarioData) => {
      const { id, slug } = scenarioData;

      return (
        <Link key={id} to={`/scenario/${slug}`}>
          <ScenarioCard isMobile={isMobile} {...scenarioData} />
        </Link>
      );
    });
  };

  const renderSelectedPlayerAmount = () => {
    if (playerAmount) {
      return (
        <Fragment>
          <span style={{ fontSize: 48, fontWeight: 600 }}>{playerAmount}</span>
          <span style={{ fontSize: 20, fontWeight: 800 }}>人</span>
        </Fragment>
      );
    } else {
      return <span style={{ fontSize: 48, fontWeight: 800 }}>不限</span>;
    }
  };

  const renderPeopleTicker = () => {
    return avaialblePlayerList.map((count, idx) => {
      return (
        <span
          key={`${count}-${idx}`}
          style={playerAmount >= count ? { color: "#1E1F2D" } : {}}
        >
          {count}
        </span>
      );
    });
  };

  const renderPlayerSelectText = () => {

    if (playerAmount) {
      return `${playerAmount} 人`;
    } else {
      return isMobile ? "人數" : "劇本人數";
    }
  }

  const renderDatePickerText = () => {
    const isStartDateMoment =
      dateRange.startDate && moment.isMoment(dateRange.startDate);
    const isEndDateMoment = dateRange.endDate && moment.isMoment(dateRange.endDate);

    const format = isMobile ? "MM/DD" : "YYYY/MM/DD";

    if (
      isStartDateMoment &&
      isEndDateMoment &&
      dateRange.startDate.isSame(dateRange.endDate)
    ) {
      return dateRange.startDate.format(format);
    } else if (isStartDateMoment && isEndDateMoment) {
      return (
        <Fragment>
          <span>{dateRange.startDate.format(format)}</span>
          <ArrowForwardIcon />
          <span>{dateRange.endDate.format(format)}</span>
        </Fragment>
      );
    } else {
      return "日期篩選";
    }
  };

  const renderConditionBar = () => {

    return (
      <div className={cx("search-condition-bar")} ref={barRef}>
        <HStack justify={{ base: "center", md: "flex-start" }}>
          <Popover
            isOpen={isPlayerSelectorOpen}
            placement="bottom-start"
            closeOnBlur={true}
            autoFocus={false}
            onOpen={() => togglePlayerSelectorOpen(true)}
            onClose={() => togglePlayerSelectorOpen(false)}
          >
            <PopoverTrigger>
              <Button
                bg="#FFFFFF"
                color={playerAmount ? "#C25A36" : "#1F2132"}
                fontSize="15px"
                px={7}
                py={5}
                leftIcon={
                  <Icon
                    as={BsPerson}
                    boxSize="18px"
                    color={playerAmount ? "#C25A36" : "#1F2132"}
                  />
                }
                w={isMobile ? "90px" : "154px"}
              >
                {renderPlayerSelectText()}
              </Button>
            </PopoverTrigger>
            <PopoverContent p={5}>
              <PopoverArrow />
              <PopoverBody>
                <Button
                  variant="ghost"
                  color="#1E1F2D"
                  onClick={handlePlayerAmountReset}
                >
                  不限
                </Button>
                <Box align="center" color="#000000">
                  {renderSelectedPlayerAmount()}
                </Box>
                <Slider
                  w="95%"
                  value={playerAmount}
                  min={4}
                  max={10}
                  step={1}
                  size="lg"
                  onChange={handlePlayerAmountChange}
                >
                  <SliderTrack bg="#BEBEBE">
                    <SliderFilledTrack bg="#1E1F2D" />
                  </SliderTrack>
                  <SliderThumb bg="#1E1F2D" />
                </Slider>
                <Flex
                  justifyContent="space-between"
                  fontSize="16px"
                  fontWeight={800}
                  color="#BEBEBE"
                  mb={3}
                >
                  {renderPeopleTicker()}
                </Flex>
                <Button
                  bg="#C25A36"
                  color="#FFFFFF"
                  fontSize="15px"
                  isFullWidth
                  onClick={() => togglePlayerSelectorOpen(false)}
                >
                  確認
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {/* <Popover
            placement="bottom-start"
            isOpen={isDatePickerOpen}
            closeOnBlur={false}
            autoFocus={false}
          >
            <PopoverTrigger>
              <Button
                bg="#FFFFFF"
                color={isDateSet ? "#C25A36" : "#1F2132"}
                fontSize="15px"
                pl={5}
                pr={5}
                py={5}
                flexGrow={1}
                w={isMobile ? "250px" : "auto"}
                textAlign="left"
                leftIcon={
                  <CalendarIcon
                    boxSize="18px"
                    color={isDateSet ? "#C25A36" : "#1F2132"}
                  />
                }
                onClick={() => toggleDatePickerOpen(true)}
              >
                {renderDatePickerText()}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              outline="none"
              borderColor="transparent"
              bg="transparent"
            >
              <PopoverArrow />
              <PopoverBody p={0}>
                <DayPickerRangeController
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onDatesChange={handleDateRangeChange}
                  minDate={MIN_DATE_OBJ}
                  maxDate={MAX_DATE_OBJ}
                  focusedInput={focusedInputDate}
                  onFocusChange={handleDateFocusedInput}
                  onOutsideClick={() => toggleDatePickerOpen(false)}
                  isOutsideRange={calculateAvailableDateRange}
                  numberOfMonths={isMobile ? 1 : 2}
                  minimumNights={0}
                  monthFormat="YYYY[年]MMMM"
                  hideKeyboardShortcutsPanel={true}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover> */}
          {!isMobile && (
            <InputGroup
              bg="#FFFFFF"
              color="rgba(63, 73, 89, 0.5)"
              fontSize="15px"
              fontWeight={700}
              borderRadius=".375rem"
              w={{ base: 'auto', md: 280, xl: 350 }}
            >
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                placeholder="輸入關鍵字"
                value={keywordInput}
                onChange={handleKeywordChange}
                color="#3F4959"
              />
            </InputGroup>
          )}
          {!isMobile && (
            <Button
              bg="#C25A36"
              color="#FFFFFF"
              fontSize="15px"
              px={7}
              py={5}
              onClick={handleKeywordSet}
            >
              搜索劇本
            </Button>
          )}
          {isMobile && (
            <Button
              bg="transparent"
              color="#FFFFFF"
              fontSize="15px"
              px={7}
              py={5}
              leftIcon={
                <Icon as={BiFilterAlt} boxSize="30px" color="#FFFFFF" />
              }
              onClick={handleDrawerOpen}
            >
              篩選
            </Button>
          )}
        </HStack>
      </div>
    );
  };

  const renderDesktopFilterColumn = () => {
    return (
      <div className={cx("scenarios-page-body__secondary-container")}>
        <div className={cx("scenarios-page__checkbox-group")}>
          <HStack mb="16px" align="center">
            <h6>劇本主類別</h6>
            <Tooltip
              label="劇本類別僅供搜尋，個別劇本的風格請參考內文介紹喔！"
              placement="bottom"
              hasArrow
              bg="#C25A36"
            >
              <IconButton
                isRound
                variant="unstyled"
                size="sm"
                bg="transparent"
                icon={<QuestionIcon boxSize="16px"/>}
                cursor="default"
              />
            </Tooltip>
          </HStack>
          <VStack align="left">
            {categoryConditionOptions.map((option, idx) => {
              const { value, text } = option;
              return (
                <Checkbox
                  key={`${value}-${idx}`}
                  value={value}
                  isChecked={selectedCategories.includes(value)}
                  onChange={handleConditionToggle("category")}
                >
                  {text}
                </Checkbox>
              );
            })}
          </VStack>
        </div>
        <div className={cx("scenarios-page__checkbox-group")}>
          <h6>難度</h6>
          <VStack align="left">
            {difficultyConditionOptions.map((option, idx) => {
              const { value, text } = option;
              return (
                <Checkbox
                  key={`${value}-${idx}`}
                  value={value}
                  isChecked={selectedDifficulties.includes(value)}
                  onChange={handleConditionToggle("difficulty")}
                >
                  {text}
                </Checkbox>
              );
            })}
          </VStack>
        </div>
        <div className={cx("scenarios-page__checkbox-group")}>
          <h6>場館</h6>
          <VStack align="left">
            {storesConditionOptions.map((option, idx) => {
              const { value, text } = option;
              return (
                <Checkbox
                  key={`${value}-${idx}`}
                  value={value}
                  isChecked={selectedStores.includes(value)}
                  onChange={handleConditionToggle("store")}
                >
                  {text}
                </Checkbox>
              );
            })}
          </VStack>
        </div>
        {dateRange.startDate && dateRange.endDate && (
          <div className={cx("scenarios-page__checkbox-group")}>
            <h6>時段</h6>
            <VStack align="left">
              {periodConditionOptions.map((option, idx) => {
                const { value, text } = option;
                return (
                  <Checkbox
                    key={`${value}-${idx}`}
                    value={value}
                    isChecked={selectedPeriods.includes(value)}
                    onChange={handleConditionToggle("period")}
                  >
                    {text}
                  </Checkbox>
                );
              })}
            </VStack>
          </div>
        )}
        <div className={cx("scenarios-page__checkbox-group")}>
          <h6>其他</h6>
          <VStack align="left">
            {otherConditionOptions.map((option, idx) => {
              const { value, text } = option;
              return (
                <Checkbox
                  key={`${value}-${idx}`}
                  value={value}
                  isChecked={selectedOtherConditions.includes(value)}
                  onChange={handleConditionToggle("other")}
                >
                  {text}
                </Checkbox>
              );
            })}
          </VStack>
        </div>
      </div>
    );
  }

  const renderMobileDrawer = () => {
    return (
      <Drawer
        isOpen={isFilterDrawerOpen}
        placement="right"
        size="xs"
        onClose={handleDrawerClose}
        closeOnOverlayClick={true}
      >
        <DrawerOverlay>
          <DrawerContent bg="#FCFCFC">
            <DrawerCloseButton color="#000000" />
            <DrawerBody my={5}>
              <div className={cx("scenarios-page-drawer__condition-section")}>
                <h6>關鍵字</h6>
                <Input
                  borderRadius="4px"
                  placeholder="輸入關鍵字"
                  value={keywordInput}
                  onChange={handleKeywordChange}
                  color="#3F4959"
                />
              </div>
              <div className={cx("scenarios-page-drawer__condition-section")}>
                <h6>劇本主類別</h6>
                <div className={cx("condition-buttons-container")}>
                  {categoryConditionOptions.map((option, idx) => {
                    const { value, text } = option;
                    return (
                      <button
                        key={`${value}-${idx}`}
                        className={cx({
                          "is-active": selectedCategories.includes(value),
                        })}
                        value={value}
                        onClick={handleConditionToggle("category")}
                      >
                        {text}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className={cx("scenarios-page-drawer__condition-section")}>
                <h6>難度</h6>
                <div className={cx("condition-buttons-container")}>
                  {difficultyConditionOptions.map((option, idx) => {
                    const { value, text } = option;
                    return (
                      <button
                        key={`${value}-${idx}`}
                        className={cx({
                          "is-active": selectedDifficulties.includes(value),
                        })}
                        value={value}
                        onClick={handleConditionToggle("difficulty")}
                      >
                        {text}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className={cx("scenarios-page-drawer__condition-section")}>
                <h6>場館</h6>
                <div className={cx("condition-buttons-container")}>
                  {storesConditionOptions.map((option, idx) => {
                    const { value, text } = option;
                    return (
                      <button
                        key={`${value}-${idx}`}
                        className={cx({
                          "is-active": selectedStores.includes(value),
                        })}
                        value={value}
                        onClick={handleConditionToggle("store")}
                      >
                        {text}
                      </button>
                    );
                  })}
                </div>
              </div>
              {dateRange.startDate && dateRange.endDate && (
                <div className={cx("scenarios-page-drawer__condition-section")}>
                  <h6>時段</h6>
                  <div className={cx("condition-buttons-container")}>
                    {periodConditionOptions.map((option, idx) => {
                      const { value, text } = option;
                      return (
                        <button
                          key={`${value}-${idx}`}
                          className={cx({
                            "is-active": selectedPeriods.includes(value),
                          })}
                          value={value}
                          onClick={handleConditionToggle("period")}
                        >
                          {text}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className={cx("scenarios-page-drawer__condition-section")}>
                <h6>其他</h6>
                <div className={cx("condition-buttons-container")}>
                  {otherConditionOptions.map((option, idx) => {
                    const { value, text } = option;
                    return (
                      <button
                        key={`${text}-${idx}`}
                        className={cx({
                          "is-active": selectedOtherConditions.includes(value),
                        })}
                        value={value}
                        onClick={handleConditionToggle("other")}
                      >
                        {text}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className={cx("scenarios-page-drawer__action-buttons")}>
                <button
                  onClick={handleConditionReset}
                  className={cx("drawer-button--reset")}
                >
                  重設
                </button>
                <button onClick={handleDrawerClose}>套用</button>
              </div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  };

  const renderToTop = () => {
    return (
      <button className={"go-top-button"} onClick={handleToTop} ref={topBtnRef}>回到頂部</button>
    )
  };

  return (
    <div className={cx("scenarios-page")}>
      <Helmet>
        <title>劇本列表 | 謀殺衛斯理 Mwlarp.com</title>
      </Helmet>
      {isFetchingData && <LoadingSpinner />}
      <div className={cx("scenarios-page-inner")}>
        <div className={cx("scenarios-page-body")}>
          {!isMobile && renderDesktopFilterColumn()}
          <div className={cx("scenarios-page-body__primary-container")}>
            {renderConditionBar()}
            <div className={cx("scenarios-cards-container")}>
              {renderCards()}
            </div>
          </div>
        </div>
      </div>
      {isMobile && renderMobileDrawer()}
      {isMobile && renderToTop()}
      <Footer />
    </div>
  );
};

export default Scenarios;
