import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
  Home,
  Scenario,
  Scenarios,
  Order,
  Post,
  Reservation,
  Navbar,
  NotFound,
  Messenger,
} from "../components";
import { theme } from './theme'
import Context from './context';

SwiperCore.use([Navigation, Pagination]);

moment.locale("zh-TW");

const getApiBase = () => {
  switch (window.location.host) {
    case "mwlarp.com":
    case "www.mwlarp.com":
      return "https://api.mwlarp.com/v1";
    case "staging.mwlarp.com":
    default:
      return "https://api-staging.mwlarp.com/v1";
      // return "http://api.lvh.me:3001/v1";
  }
};

const apiBase = getApiBase();

const getData = (endpoint) => {
  return fetch(`${apiBase}${endpoint}`, {
    headers: {
      'Authorization': 'Basic bWltaWJpZ2ZhdGJ1dHQ6b25seXRocmVlc2Vjb25kcw==\n',
    },
  })
};

function App () {
  const [isMobile, setIsMobile] = useState(false);
  const [scenariosDataInStore, setScenariosDataInStore] = useState([]);

  const globalStates = {
    isMobile,
    getData,
    scenariosDataInStore,
    setScenariosDataInStore,
  };

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', () => {
      handleResize()
    })
  }, []);

  const handleResize = () => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    };
  }

  return (
    <ChakraProvider theme={theme}>
      <Context.Provider value={globalStates}>
        <BrowserRouter>
          <Navbar />
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route path="/scenarios/:category?" render={() => <Scenarios />} />
            <Route exact path="/scenario/:slug" render={() => <Scenario />} />
            <Route exact path="/order/:slug" render={() => <Order />} />
            <Route exact path="/post/:slug" render={() => <Post />} />
            <Route exact path="/reservation" render={() => <Reservation />} />
            <Route exact path="/oops" render={() => <NotFound />} />
            <Route render={() => <NotFound />} />
          </Switch>
          <Messenger />
        </BrowserRouter>
      </Context.Provider>
    </ChakraProvider>
  );
}

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement("div"))
  );
});
